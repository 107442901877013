import * as serviceWorker from './serviceWorker'
import { createRoot } from 'react-dom/client'
import './index.css'
import App from './App'

const container = document.getElementById('app')
const root = createRoot(container!)
root.render(<App />)

serviceWorker.register()
