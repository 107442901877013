import React from 'react'

export const Theme = {
  PrivateLayout: {
    AppBar: {
      // BackgroundColor: window.env.name === 'KB_PROD' ? '#fefefe' ''
    },
  },
}

export const Logotype = () => {
  if (window.env.name === 'KB_PROD') {
    return <img style={{ width: 240 }} src="/logo_kustbud.png" alt="img" />
  }

  return (
    <>
      <img style={{ width: 75 }} src="/logo_small2.png" alt="img" />
      <img style={{ width: 225 }} src="/logo_small.png" alt="img" />
    </>
  )
}

export const BrandName = () => {
  if (window.env.name === 'KB_PROD') {
    return (
      <span
        style={{
          fontWeight: 400,
        }}
      >
        Kustbud.se
      </span>
    )
  }
  return 'SwePelago'
}
