import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import { toggleDrawer } from '../../redux/reducers/layout'
import PrivateLayout from './PrivateLayout'

const mapStateToProps = (state) => ({
  user: state.login.user,
  token: state.login.token,
  expires: state.login.expires,
  ...state.layout,
})

const mapDispatchToProps = (dispatch) => ({
  toggleDrawer: () => {
    dispatch(toggleDrawer())
  },
  push: (url) => {
    dispatch(push(url))
  },
})

const ConnectedPrivateLayout = connect(
  mapStateToProps,
  mapDispatchToProps
)(PrivateLayout)

export default connect(mapStateToProps, mapDispatchToProps)(PrivateLayout)
