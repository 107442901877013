import axios from 'axios'
import { setSnackbar } from '../snackbar'
import { Roles } from '../../../helpers/permissions'
import { fetchAll as fetchOrganisations } from '../organisations'

const initialState = {
  list: [],
  userToAddEmail: '',
  userToAddOrganisation: null,
  userToAddRoles: [],
  objectToView: {},
  selectedRoles: [],
  selectedCompany: null,
  isLoadingFetch: false,
  isLoadingCreate: false,
  isLoadingFetchSingle: false,
  isCreateNewUserModalOpen: false,
}

const FETCH_USERS_START = 'FETCH_USERS_START'
const fetchUsersStart = () => ({
  type: FETCH_USERS_START,
})

const FETCH_USERS_FAIL = 'FETCH_USERS_FAIL'
const fetchUsersFail = () => ({
  type: FETCH_USERS_FAIL,
})

const FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS'
const fetchUsersSuccess = (userList) => ({
  type: FETCH_USERS_SUCCESS,
  payload: userList,
})

export const fetchAll = () => async (dispatch, getState) => {
  dispatch(fetchUsersStart())
  try {
    const { data } = await axios.get('/v2/users')
    dispatch(fetchUsersSuccess(data))
    const { user } = getState().login
    const isAdmin =
      user.roles && user.roles.find((userRole) => userRole === Roles.ADMIN)
    if (isAdmin) dispatch(fetchOrganisations())
  } catch (e) {
    dispatch(fetchUsersFail())
    dispatch(setSnackbar('Ett oväntat fel uppstod', 'error'))
  }
}

const TOGGLE_CREATE_USER_MODAL = 'TOGGLE_CREATE_USER_MODAL'
export const toggleCreateUserModal = () => ({
  type: TOGGLE_CREATE_USER_MODAL,
})

const USER_TO_ADD_EMAIL_CHANGED = 'USER_TO_ADD_EMAIL_CHANGED'
export const userToAddEmailChanged = (email) => ({
  type: USER_TO_ADD_EMAIL_CHANGED,
  payload: email,
})

const USER_TO_ADD_ORGANISATION_CHANGED = 'USER_TO_ADD_ORGANISATION_CHANGED'
export const userToAddOrganisatonChanged = (organisationId) => ({
  type: USER_TO_ADD_ORGANISATION_CHANGED,
  payload: organisationId,
})

const USER_TO_ADD_ROLES_CHANGED = 'USER_TO_ADD_ROLES_CHANGED'
export const userToAddRolesChanged = (role) => ({
  type: USER_TO_ADD_ROLES_CHANGED,
  payload: role,
})

const USER_OBJECT_TO_CREATE_CHANGED = 'USER_OBJECT_TO_CREATE_CHANGED'
export const onObjectToCreateChange = (object) => ({
  type: USER_OBJECT_TO_CREATE_CHANGED,
  payload: object,
})

const CREATE_USER_START = 'CREATE_USER_START'
const createUserStart = () => ({
  type: CREATE_USER_START,
})

const CREATE_USER_FAIL = 'CREATE_USER_FAIL'
const createUserFail = () => ({
  type: CREATE_USER_FAIL,
})

const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS'
const createUserSuccess = (data) => ({
  type: CREATE_USER_SUCCESS,
  payload: data,
})

const sleep = (ms) =>
  new Promise((resolve) => {
    setTimeout(resolve, ms)
  })

export const createUser = (organisationId) => async (dispatch, getState) => {
  // eslint-disable-next-line
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  const { userToAddEmail, userToAddRoles } = getState().userList
  dispatch(createUserStart())
  try {
    if (!re.test(userToAddEmail.toLowerCase()))
      throw new Error(
        'Ogiltig emailadress. Vänligen kontrollera och försök igen.'
      )
    await sleep(2000)
    const postData = { email: userToAddEmail }
    if (organisationId) postData.organisationId = organisationId
    let userRoles
    if (userToAddRoles) userRoles = userToAddRoles.map((role) => role.value)

    postData.roles = userRoles
    const { data } = await axios.post('/v2/users', postData)
    dispatch(createUserSuccess(data))
    dispatch(setSnackbar(`En inbjudan har skickats till ${userToAddEmail}`))
  } catch (e) {
    dispatch(createUserFail())
    dispatch(setSnackbar(e, 'error'))
  }
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_USERS_START:
      return { ...state, isLoadingFetch: true }
    case FETCH_USERS_FAIL:
      return { ...state, isLoadingFetch: false }
    case FETCH_USERS_SUCCESS:
      return { ...state, isLoadingFetch: false, list: action.payload }
    case TOGGLE_CREATE_USER_MODAL:
      return {
        ...state,
        isCreateNewUserModalOpen: !state.isCreateNewUserModalOpen,
      }
    case USER_TO_ADD_EMAIL_CHANGED: {
      return { ...state, userToAddEmail: action.payload }
    }
    case USER_TO_ADD_ORGANISATION_CHANGED: {
      return { ...state, userToAddOrganisation: action.payload }
    }
    case USER_TO_ADD_ROLES_CHANGED: {
      return { ...state, userToAddRoles: action.payload }
    }
    case USER_OBJECT_TO_CREATE_CHANGED:
      return { ...state, objectToCreate: action.payload }
    case CREATE_USER_START:
      return { ...state, isLoadingCreate: true }
    case CREATE_USER_FAIL:
      return { ...state, isLoadingCreate: false }
    case CREATE_USER_SUCCESS: {
      const list = [...state.list]
      list.push(action.payload)
      return {
        ...state,
        isLoadingCreate: false,
        userToAddEmail: '',
        userToAddOrganisation: null,
        isCreateNewUserModalOpen: false,
        list,
      }
    }
    default:
      return state
  }
}

export default reducer
