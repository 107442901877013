import React from 'react'

const Column = (props) => {
  const { justify, align, flex, style, ...rest } = props
  const customStyle = props.style || {}
  customStyle.display = 'flex'
  customStyle.flexDirection = 'column'
  if (justify) customStyle.justifyContent = justify
  if (align) customStyle.alignItems = align
  if (flex) customStyle.flex = 1
  return <div style={customStyle} {...rest} />
}

export default Column
