const initialState = {
  isOpen: false,
}

const TOGGLE_MENU_DRAWER = 'TOGGLE_MENU_DRAWER'
export const toggleDrawer = () => ({
  type: TOGGLE_MENU_DRAWER,
})

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_MENU_DRAWER:
      return { ...state, isOpen: !state.isOpen }
    default:
      return state
  }
}

export default reducer
