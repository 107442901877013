import React, { useEffect } from 'react'
import axios from 'axios'
import 'typeface-roboto'
import { ThemeProvider } from '@mui/material/styles'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { createTheme } from '@material-ui/core/styles'
// import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import moment from 'moment'
import 'moment/locale/sv'
import './utils/localization'
import Router from './Router'
import { store } from './redux'
import packageJson from '../package.json'
import './env'
import './theme.css'

const version = packageJson.version

const theme = createTheme({
  palette: {
    // mode: 'light', // 'dark
    primary: { main: '#2196f3' },
    secondary: { main: '#e91e50' },
  },
  status: {
    danger: 'orange',
  },
  typography: {
    fontSize: 14,
    body1: {
      // letterSpacing: 0.5
    },
  },
  overrides: {
    MuiTextField: {
      root: {
        width: '100%',
      },
    },
    MuiButton: {
      root: {
        width: '100%',
      },
    },
  },
})

axios.defaults.baseURL = window.env.API_BASE_URL
axios.interceptors.request.use((config) => {
  const token = localStorage.getItem('token')
  // eslint-disable-next-line
  config.headers.Authorization = `Bearer ${token}`
  return config
})

function App() {
  useEffect(() => {
    document.title =
      window.env && window.env.name === 'KB_PROD' ? 'Kustbud' : 'Swepelago'
  }, [])

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <ThemeProvider theme={theme}>
        <div className="App">
          {/* <MuiPickersUtilsProvider utils={MomentUtils}> */}
          <Router store={store} />
          {/* </MuiPickersUtilsProvider> */}
          <span
            style={{
              zIndex: 9999,
              position: 'fixed',
              bottom: 5,
              left: 15,
              color: '#111',
              fontSize: 10,
              fontWeight: 300,
              opacity: 0.1,
            }}
          >
            v{version}
          </span>
        </div>
      </ThemeProvider>
    </LocalizationProvider>
  )
}

export default App
