const initialState = {
  message: null,
  variant: 'success',
  isOpen: false,
}

const TOGGLE_SNACKBAR = 'TOGGLE_SNACKBAR'
export const toggleSnackbar = () => ({
  type: TOGGLE_SNACKBAR,
})

const HANDLE_SNACKBAR = 'snackbar/HANDLE_SNACKBAR'
const handleSnackbar = (message, variant) => ({
  type: HANDLE_SNACKBAR,
  payload: { message, variant },
})

export const setSnackbar =
  (messageOrError, variant = 'success') =>
  (dispatch) => {
    let message = messageOrError
    if (messageOrError instanceof Error) {
      // Check for axios response
      if (messageOrError.response) {
        const { data, status, config } = messageOrError.response
        const { url, method } = config
        message = `${method.toUpperCase()} ${url} (${status}: ${data})`
      } else {
        // eslint-disable-next-line
      message = messageOrError.message
      }
    }
    dispatch(handleSnackbar(message, variant))
  }

const snackbar = (state = initialState, action) => {
  switch (action.type) {
    case HANDLE_SNACKBAR:
      return {
        ...state,
        variant: action.payload.variant,
        message: action.payload.message,
        isOpen: true,
      }
    case TOGGLE_SNACKBAR:
      return { ...state, isOpen: false }
    default:
      return state
  }
}

export default snackbar
