import axios from 'axios'
import { stringify } from 'qs'
import { replace } from '../../../helpers/immutable'

const initialState = {
  list: [],
  order: null,
  isLoadingFetch: false,
  isLoadingCreateOrUpdate: false,
  isLoadingRemove: false,
  isCreatingOrUpdating: false,
}

/* TOGGLE FIELDS IN TABLE */
const TOGGLE_CREATE_OR_UPDATE = 'orders/TOGGLE_CREATE_OR_UPDATE'
export const toggleCreateOrUpdate = (order) => ({
  type: TOGGLE_CREATE_OR_UPDATE,
  payload: order,
})

/* HANDLE_ORDER_CHANGE_FUNCTIONS */

const FETCH_ALL_START = 'orders/FETCH_ALL_START'
const fetchAllStart = () => ({
  type: FETCH_ALL_START,
})

const FETCH_ALL_FAIL = 'orders/FETCH_ALL_FAIL'
const fetchAllFail = () => ({
  type: FETCH_ALL_FAIL,
})

const FETCH_ALL_SUCCESS = 'orders/FETCH_ALL_SUCCESS'
const fetchAllSuccess = (list) => ({
  type: FETCH_ALL_SUCCESS,
  payload: list,
})

export const fetchAll = (filter) => async (dispatch) => {
  const user = JSON.parse(localStorage.getItem('user'))
  let url
  const qsFilter = stringify(filter)
  if (user && user.role === 'ADMIN') url = `/admin/orders?${qsFilter}`
  else url = `/partner/orders?${qsFilter}`
  dispatch(fetchAllStart())
  try {
    const { data } = await axios.get(url)
    dispatch(fetchAllSuccess(data))
  } catch (err) {
    dispatch(fetchAllFail(err))
  }
}

/* DELETE */
const REMOVE_ORDER_START = 'REMOVE_ORDER_START'
const removeOrderStart = () => ({
  type: REMOVE_ORDER_START,
})

const REMOVE_ORDER_FAIL = 'REMOVE_ORDER_FAIL'
const removeOrderFail = () => ({
  type: REMOVE_ORDER_FAIL,
})

const REMOVE_ORDER_SUCCESS = 'REMOVE_ORDER_SUCCESS'
const removeOrderSuccess = () => ({
  type: REMOVE_ORDER_SUCCESS,
})

export const remove = (order) => async (dispatch) => {
  dispatch(removeOrderStart())
  try {
    const { data } = await axios.delete(`/admin/orders/${order.id}`)
    dispatch(removeOrderSuccess(data))
  } catch (e) {
    dispatch(removeOrderFail())
  }
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_CREATE_OR_UPDATE:
      return { ...state, order: action.payload }
    case FETCH_ALL_START:
      return { ...state, isLoadingFetch: true }
    case FETCH_ALL_FAIL:
      return { ...state, isLoadingFetch: false }
    case FETCH_ALL_SUCCESS:
      return { ...state, isLoadingFetch: false, list: action.payload }
    case REMOVE_ORDER_START:
      return { ...state, isLoadingRemove: true }
    case REMOVE_ORDER_FAIL:
      return { ...state, isLoadingRemove: false }
    case REMOVE_ORDER_SUCCESS: {
      const list = replace({ id: action.payload.id }, null, state.list)
      return { ...state, isLoadingRemove: false, list }
    }
    default:
      return state
  }
}

export default reducer
