import axios from 'axios'
import { push } from 'connected-react-router'

const initialState = {
  token: null,
  isLoadingRecover: false,
}

const sleep = (ms) =>
  new Promise((resolve) => {
    setTimeout(resolve, ms)
  })

const SET_RECOVERY_TOKEN = 'SET_RECOVERY_TOKEN'
export const setRecoveryToken = (token) => ({
  type: SET_RECOVERY_TOKEN,
  payload: token,
})

const RECOVER_USER_PASSWORD_START = 'RECOVER_USER_PASSWORD_START'
const recoverUserPasswordStart = () => ({
  type: RECOVER_USER_PASSWORD_START,
})

const RECOVER_USER_PASSWORD_FAIL = 'RECOVER_USER_PASSWORD_FAIL'
const recoverUserPasswordFail = () => ({
  type: RECOVER_USER_PASSWORD_FAIL,
})

const RECOVER_USER_PASSWORD_SUCCESS = 'RECOVER_USER_PASSWORD_SUCCESS'
const recoverUserPasswordSuccess = () => ({
  type: RECOVER_USER_PASSWORD_SUCCESS,
})

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_RECOVERY_TOKEN:
      return { ...state, token: action.payload }
    case RECOVER_USER_PASSWORD_START:
      return { ...state, isLoadingRecover: true }
    case RECOVER_USER_PASSWORD_FAIL:
      return { ...state, isLoadingRecover: false }
    case RECOVER_USER_PASSWORD_SUCCESS:
      return { ...state, isLoadingRecover: false, token: null }
    default:
      return state
  }
}

export default reducer
