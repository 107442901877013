import axios from 'axios'
import { setEmail, setPassword, login } from './login'
import { setSnackbar } from './snackbar'

const initialState = {
  user: {},
  token: null,
  isLoadingRegister: false,
  isLoadingVerifyToken: false,
  isValidToken: false,
}

const sleep = (ms) =>
  new Promise((resolve) => {
    setTimeout(resolve, ms)
  })

const SET_TOKEN = 'SET_TOKEN'
const setToken = (token) => ({
  type: SET_TOKEN,
  payload: token,
})

const VERIFY_USER_TOKEN_START = 'VERIFY_USER_TOKEN_START'
const verifyUserTokenStart = () => ({
  type: VERIFY_USER_TOKEN_START,
})

const VERIFY_USER_TOKEN_FAIL = 'VERIFY_USER_TOKEN_FAIL'
const verifyUserTokenFail = () => ({
  type: VERIFY_USER_TOKEN_FAIL,
})

const VERIFY_USER_TOKEN_SUCCESS = 'VERIFY_USER_TOKEN_SUCCESS'
const verifyUserTokenSuccess = (user) => ({
  type: VERIFY_USER_TOKEN_SUCCESS,
  payload: user,
})

export const verifyToken = (token) => async (dispatch) => {
  dispatch(verifyUserTokenStart())
  try {
    const { data } = await axios.get(`/v2/users/register/verify/${token}`)
    dispatch(verifyUserTokenSuccess(data))
    dispatch(setToken(token))
  } catch (e) {
    dispatch(verifyUserTokenFail())
    dispatch(setSnackbar('Ogiltigt token', 'error'))
  }
}

const REGISTER_USER_START = 'REGISTER_USER_START'
const registerUserStart = () => ({
  type: REGISTER_USER_START,
})

const REGISTER_USER_FAIL = 'REGISTER_USER_FAIL'
const registerUserFail = () => ({
  type: REGISTER_USER_FAIL,
})

const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS'
const registerUserSuccess = () => ({
  type: REGISTER_USER_SUCCESS,
})

export const registerUser = (user) => async (dispatch, getState) => {
  const { token } = getState().register
  dispatch(registerUserStart())
  await sleep(1500)
  user.name = `${user.firstname} ${user.lastname}`
  try {
    await axios.post('/v2/users/register', { user, token })
    dispatch(registerUserSuccess())

    dispatch(setEmail(user.email))
    dispatch(setPassword(user.password))
    dispatch(login())
    setTimeout(() => {
      window.location.reload()
    }, 2000)
  } catch (e) {
    dispatch(registerUserFail())
    dispatch(setSnackbar('Ett fel uppstod', 'error'))
  }
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_TOKEN:
      return { ...state, token: action.payload }
    case VERIFY_USER_TOKEN_START:
      return { ...state, isLoadingVerifyToken: true }
    case VERIFY_USER_TOKEN_FAIL:
      return { ...state, isLoadingVerifyToken: false, isValidToken: false }
    case VERIFY_USER_TOKEN_SUCCESS:
      return {
        ...state,
        isLoadingVerifyToken: false,
        isValidToken: true,
        user: action.payload,
      }
    case REGISTER_USER_START:
      return { ...state, isLoadingRegister: true }
    case REGISTER_USER_FAIL:
      return { ...state, isLoadingRegister: false }
    case REGISTER_USER_SUCCESS:
      return { ...state, isLoadingRegister: false }
    default:
      return state
  }
}

export default reducer
