import axios from 'axios'
import { replace } from '../../../helpers/immutable'

const initialState = {
  order: null,
  isLoadingCreateOrUpdate: false,
}

/* CREATE */
const CREATE_ORDER_START = 'CREATE_ORDER_START'
const createOrderStart = () => ({
  type: CREATE_ORDER_START,
})

const CREATE_ORDER_FAIL = 'CREATE_ORDER_FAIL'
const createOrderFail = () => ({
  type: CREATE_ORDER_FAIL,
})

const CREATE_ORDER_SUCCESS = 'CREATE_ORDER_SUCCESS'
const createOrderSuccess = (order) => ({
  type: CREATE_ORDER_SUCCESS,
  payload: order,
})

export const create = () => async (dispatch, getState) => {
  const { order } = getState.orderDetail
  dispatch(createOrderStart())
  try {
    const { data } = await axios.post('/admin/orders', order)
    dispatch(createOrderSuccess(data))
  } catch (e) {
    dispatch(createOrderFail())
  }
}

/* UPDATE */
const UPDATE_ORDER_START = 'UPDATE_ORDER_START'
const updateOrderStart = () => ({
  type: UPDATE_ORDER_START,
})

const UPDATE_ORDER_FAIL = 'UPDATE_ORDER_FAIL'
const updateOrderFail = () => ({
  type: UPDATE_ORDER_FAIL,
})

const UPDATE_ORDER_SUCCESS = 'UPDATE_ORDER_SUCCESS'
const updateOrderSuccess = (order) => ({
  type: UPDATE_ORDER_SUCCESS,
  payload: order,
})

export const update = () => async (dispatch, getState) => {
  const { order } = getState.orderDetail
  dispatch(updateOrderStart())
  try {
    const { data } = await axios.patch(`/admin/orders/${order.id}`, order)
    dispatch(updateOrderSuccess(data))
  } catch (e) {
    dispatch(updateOrderFail())
  }
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_ORDER_START:
      return { ...state, isLoadingCreateOrUpdate: true }
    default:
      return state
  }
}

export default reducer
