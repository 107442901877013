export const getObjectIdx = (obj, array) => {
  for (let i = 0; i < array.length; i += 1) {
    if (parseInt(array[i].id, 10) === parseInt(obj.id, 10)) {
      return i
    }
  }
  return -1
}
//
// export const replace = (oldObject, newObject, array) => {
//   if (!array) {
//     return []
//   }
//   const idx = getObjectIdx(oldObject, array)
//   if (idx === -1) {
//     return array
//   }
//   const newObjects = [...array]
//   if (newObject) {
//     newObjects.splice(idx, 1, newObject)
//   } else {
//     newObjects.splice(idx, 1)
//   }
//   return newObjects
// }
export const exists = (array, objToFind, compareKey) => {
  if (!array || !Array.isArray(array)) return false
  if (compareKey) {
    return (
      array.findIndex((obj) => obj[compareKey] == objToFind[compareKey]) > -1
    )
  }
  return array.findIndex((obj) => obj === objToFind) > -1
}

export const replace = (oldObject, newObject, array, compareKey) => {
  if (!array) return []
  let idx = -1
  for (let i = 0; i < array.length; i += 1) {
    const obj = array[i]
    if (compareKey && obj[compareKey] == oldObject[compareKey]) {
      idx = i
      break
    } else if (obj === oldObject) {
      idx = i
      break
    }
  }

  if (idx === -1) return array

  const newArray = [...array]
  newArray.splice(idx, 1, newObject || undefined)
  return newArray
}

export const push = (object, array) => [...array, object]

export const replaceOrPush = (oldObject, newObject, array, compareKey) => {
  if (exists(array, oldObject, compareKey)) {
    return replace(oldObject, newObject, array, compareKey)
  }
  return push(newObject, array)
}

export const remove = (object, array, compareKey) => {
  if (!array || !Array.isArray(array)) return array

  return array.filter((value) => {
    if (compareKey && value[compareKey] === object[compareKey]) {
      return false
    }
    return true
  })
}

export const flip = (object, array, compareKey) => {
  if (exists(array, object, compareKey)) {
    return remove(object, array, compareKey)
  }
  return push(object, array)
}

export const unshift = (object, array) => {
  const newArray = [...array]
  newArray.unshift(object)
  return newArray
}
