const localizationKeys = {
  /* ORDER AND PACKAGE STATUSES */
  CONFIRMED: 'Planerad',
  RECEIVED: 'Mottagen',
  INFORMATION: 'Förhandsinformation mottagen',
  FINISHED: 'Klar',
  RETURNED: 'Returnerad',
  DELIVERED: 'Levererad',
  /* ERRORS */
  ER_INVALID_CREDENTIALS: 'Fel email eller lösenord.',
  ER_NOT_FOUND: 'Ett oväntat fel uppstod.',
  ER_RESOURCE_LOCKED: 'RESOURCE_LOCKED',
  ER_USER_NO_CONNECTED_ORGANISATION:
    'Användare är inte kopplad till ett företag.',
  ER_NO_PERMISSION: 'Ett oväntat fel uppstod.',
  ER_BOAT_NOT_FOUND: 'Ingen båt hittades.',
  ER_ROUTE_NOT_FOUND: 'Ingen rutt hittades.',
  ER_COULD_NOT_CREATE: 'Ett oväntat fel uppstod.',
  ER_DELIVERY_NOT_FOUND: 'Ingen order hittades.',
  ER_LIMITED_AREAS_NOT_FOUND: 'Begränsningsområden hittades inte.',
  ER_CREATE_ADMIN_ORGANISATION_ID: 'Ett oväntat fel uppstod.',
  ER_DOCK_NOT_FOUND: 'Hamn hittades inte.',
  ER_ORDER_NOT_FOUND: 'Ingen order hittades.',
  ER_ORGANISATION_NOT_FOUND: 'Inget företag hittades.',
  ER_ROUTE_POINT_NOT_FOUND: 'Ingen punkt hittades.',
  ER_USER_NOT_FOUND: 'Ingen användare hittades.',
  ER_VERIFY_TOKEN_NOT_FOUND: 'Verifieringskoden kunde inte hittas.',
  /* ROLES */
  ADMIN: 'Administratör (Intern)',
  PARTNER_ADMIN: 'Administratör (Partner)',
  PARTNER_USER: 'Användare (Partner)',
  SUBCONTRACTOR_ADMIN: 'Administratör (Underentreprenör)',
  SUBCONTRACTOR_USER: 'Användare (Underentreprenör)',
  DRIVER: 'Förare',
  /* Organisation types */
  SUBCONTRACTOR: 'Underentreprenör',
  PARTNER: 'Partner',
  INTERNAL: 'Intern',

  se: {
    Error: {
      Login: {
        InvalidCredentials: 'Mailadressen eller lösenordet matchar inte.',
      },
    },
    User: {
      Registered: 'Registrerad',
      PendingRegistration: 'Inväntar registrering',
      Roles: {
        ADMIN: 'Administratör (Intern)',
        PARTNER_ADMIN: 'Administratör (Partner)',
        PARTNER_USER: 'Användare (Partner)',
        SUBCONTRACTOR_ADMIN: 'Administratör (Underentreprenör)',
        SUBCONTRACTOR_USER: 'Användare (Underentreprenör)',
        DRIVER: 'Förare',
        MEMBER: 'Medlem',
      },
    },
    Organisation: {
      Types: {
        SUBCONTRACTOR: 'Underentreprenör',
        PARTNER: 'Partner',
        INTERNAL: 'Intern',
      },
    },
    Driver: {
      Main: {
        FetchingInformation: 'Vänta lite medan vi hämtar information..',
      },
    },
    Components: {
      FileUpload: {
        BrowseButton: 'Bläddra',
        DragAndDrop: 'Dra & släpp bild(er) här',
        Or: 'eller',
      },
      PullToRefresh: {
        Loading: 'Uppdaterar..',
        ReleaseToRefresh: 'Släpp för att uppdatera',
        PullDownToRefresh: 'Dra ned för att uppdatera',
      },
    },
    Constants: {
      BoatType: {
        TAXI: 'Taxi',
        GOODS: 'Gods',
      },
      BoatStatus: {
        AVAILABLE: 'Tillgänglig',
        UNAVAILABLE: 'Ej tillgänglig',
      },
    },
  },
  en: {},
}

const getValueAtPath = (object, path, defaultValue) => {
  if (typeof object !== 'object') return defaultValue
  if (typeof path !== 'string') return defaultValue

  const pathParts = path.split('.')
  let currentObject = object
  // eslint-disable-next-line
  for (let i = 0; i < pathParts.length; i += 1) {
    const part = pathParts[i]
    const isLastPart = i === pathParts.length - 1

    currentObject = currentObject[part]
    if (isLastPart) {
      return currentObject || defaultValue
    }

    if (typeof currentObject !== 'object') return defaultValue
  }

  return defaultValue
}

global._ = (key) => {
  const language = global.SWPL_LANG || 'se'
  const path = `${language}.${key}`
  return getValueAtPath(localizationKeys, path, key)
}
export default (key) => localizationKeys[key] || key
