import { createStore, applyMiddleware, combineReducers, compose } from 'redux'
import thunkMiddleware from 'redux-thunk'
import { createBrowserHistory } from 'history'
import { connectRouter, routerMiddleware } from 'connected-react-router'
import loginReducer from './reducers/login'
import layoutReducer from './reducers/layout'
import orderListReducer from './reducers/order/list'
import orderDetailReducer from './reducers/order/detail'
import dockReducer from './reducers/docks'
import boatReducer from './reducers/boats'
import limitedAreasReducer from './reducers/limitedAreas'
import userListReducer from './reducers/users/list'
import organisationsReducer from './reducers/organisations'
import snackbarReducer from './reducers/snackbar'
import registerReducer from './reducers/register'
import recoverReducer from './reducers/recover'

const initialState = {}

const loggerMiddleware = (store) => (next) => (action) => {
  console.log('Action type:', action.type)
  console.log('Action payload:', action.payload)
  console.log('State before:', store.getState())
  next(action)
  console.log('State after:', store.getState())
}

export const history = createBrowserHistory()

const rootReducer = combineReducers({
  router: connectRouter(history),
  layout: layoutReducer,
  snackbar: snackbarReducer,
  orderList: orderListReducer,
  orderDetail: orderDetailReducer,
  docks: dockReducer,
  boats: boatReducer,
  limitedAreas: limitedAreasReducer,
  userList: userListReducer,
  organisations: organisationsReducer,
  register: registerReducer,
  recover: recoverReducer,
  login: loginReducer,
})

const isDev = false // process && process.env && process.env.NODE_ENV === 'development'
let middlewares
if (isDev) {
  middlewares = applyMiddleware(
    thunkMiddleware,
    routerMiddleware(history),
    loggerMiddleware
  )
} else {
  middlewares = applyMiddleware(thunkMiddleware, routerMiddleware(history))
}
const enhancers = compose(middlewares)

export const store = createStore(rootReducer, initialState, enhancers)

export default store
