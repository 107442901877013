import React from 'react'
import { Navigate, Link, useLocation, useNavigate } from 'react-router-dom'
import classNames from 'classnames'
import { withStyles } from '@material-ui/core/styles'
import Drawer from '@material-ui/core/Drawer'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import List from '@material-ui/core/List'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Button from '@material-ui/core/Button'
import CssBaseline from '@material-ui/core/CssBaseline'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import Tooltip from '@material-ui/core/Tooltip'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import { Icon } from '@material-ui/core'
import items from './items'
import Themes from '../../Themes'
import { BrandName } from '../Brand'
import Modal from '../Modal'
import OrganisationPicker from '../OrganisationPicker'

const drawerWidth = 220

const styles = (theme) => ({
  root: {
    display: 'flex',
    flex: 1,
  },
  appBar: {
    backgroundColor: Themes.primary,
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(8),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  userMenuButton: {
    color: '#fff',
  },
  content: {
    flexGrow: 1,
    minHeight: '100%',
    overflow: 'auto',
    padding: '64px 24px',
  },
  menuItem: {
    '&:hover $child': {
      color: '#e91e50',
    },
  },
  highlight: {
    color: '#e91e50',
  },
  child: { fontSize: 16 },
  button: {
    padding: 18,
    boxSizing: 'border-box',
    width: '100%',
    backgroundColor: '#e91e50',
    borderRadius: 500,
    textTransform: 'uppercase',
    color: '#fff',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 16,
    fontWeight: 800,
  },
})

const PrivateLayout = (props) => {
  const location = useLocation()
  const navigate = useNavigate()
  const [userMenuAnchorEl, setUserMenuAnchorEl] = React.useState(null)
  const [organisationPickerOpen, setOrganisationPickerOpen] =
    React.useState(null)

  const handleUserMenuClick = (event) => {
    setUserMenuAnchorEl(event.currentTarget)
  }

  const handleUserMenuClose = () => {
    setUserMenuAnchorEl(null)
  }

  const { classes, theme, isOpen, user, token, expires } = props

  let authorized = true
  const currentTime = new Date().getTime() / 1000
  if (!user || !token || !expires || expires < currentTime) {
    authorized = false
  }

  if (!authorized) {
    localStorage.setItem('returnTo', location.pathname)
    localStorage.removeItem('application')
    return <Navigate to="/login" />
  }

  const application = localStorage.getItem('application')
  if (
    (user.roles.includes('DRIVER') && application === 'driver') ||
    user.roles.every((role) => role === 'DRIVER')
  ) {
    return <Navigate to="/driver" />
  }

  if (user.roles.includes('DRIVER') && user.roles.length > 1 && !application) {
    return (
      <Modal open title="Välj applikation">
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            flexGrow: 1,
          }}
        >
          <div
            className={classes.button}
            onClick={() => {
              localStorage.setItem('application', 'portal')
              navigate('/home')
            }}
          >
            <span>Skärgårdsportalen</span>
          </div>
          <div
            className={classes.button}
            style={{
              marginTop: 24,
            }}
            onClick={() => {
              localStorage.setItem('application', 'driver')
              navigate('/driver')
            }}
          >
            <span>Förarapplikationen</span>
          </div>
        </div>
      </Modal>
    )
  }

  if (user.roles.every((role) => role === 'DRIVER')) {
    return <Navigate to="/driver" />
  }

  return (
    <div className={classes.root}>
      <CssBaseline />
      <OrganisationPicker
        open={organisationPickerOpen}
        onClose={() => setOrganisationPickerOpen(false)}
      />
      <AppBar
        position="fixed"
        className={classNames(classes.appBar, {
          [classes.appBarShift]: isOpen,
        })}
      >
        <Toolbar disableGutters={!isOpen} style={{ display: 'flex' }}>
          <IconButton
            color="inherit"
            aria-label="Open drawer"
            onClick={props.toggleDrawer}
            className={classNames(classes.menuButton, {
              [classes.hide]: isOpen,
            })}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            variant="h5"
            style={{ fontWeight: 300 }}
            color="inherit"
            noWrap
          >
            <BrandName />
          </Typography>
          <div
            style={{
              marginLeft: 'auto',
              marginRight: 20,
            }}
          >
            <Button
              className={classes.userMenuButton}
              onClick={handleUserMenuClick}
            >
              <Icon style={{ fontSize: 28 }}>face</Icon>
              {/* <SmileyIcon style={{ fontSize: 40 }} /> */}
              <div style={{ margin: '0 24px' }}>
                <Typography
                  variant="subtitle2"
                  style={{
                    textAlign: 'left',
                    color: '#fff',
                    textTransform: 'none',
                  }}
                >
                  {user.name}
                </Typography>
                <Typography
                  variant="body2"
                  style={{
                    textAlign: 'left',
                    color: '#fff',
                    textTransform: 'none',
                  }}
                >
                  {user.organisation.name}
                </Typography>
              </div>
              <Icon style={{ fontSize: 28 }}>expand_more</Icon>
            </Button>
            <Menu
              anchorEl={userMenuAnchorEl}
              open={!!userMenuAnchorEl}
              onClose={handleUserMenuClose}
            >
              <MenuItem
                onClick={() =>
                  navigate(`/users/${user.organisation.id}/${user.id}`)
                }
              >
                Min profil
              </MenuItem>
              <MenuItem onClick={() => setOrganisationPickerOpen(true)}>
                Byt organisation
              </MenuItem>
              <MenuItem onClick={() => navigate('/logout')}>Logga ut</MenuItem>
            </Menu>
          </div>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        className={classNames(classes.drawer, {
          [classes.drawerOpen]: isOpen,
          [classes.drawerClose]: !isOpen,
        })}
        classes={{
          paper: classNames({
            [classes.drawerOpen]: isOpen,
            [classes.drawerClose]: !isOpen,
          }),
        }}
        open={isOpen}
      >
        <div className={classes.toolbar}>
          <IconButton onClick={props.toggleDrawer}>
            {theme.direction === 'rtl' ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </div>
        <Divider />
        <List>
          {items.map((item) => {
            const { name, icon } = item
            let { path } = item
            if (typeof path === 'function') path = path(user)

            const isRouteActive = (itemPath) => {
              if (path === location.pathname) return true
              if (location.pathname.split('/')[1] === itemPath.split('/')[1])
                return true
              return false
            }

            const orgTypes = user?.organisation?.organisationTypes?.map?.(
              (t) => t.type
            )
            if (item.access.every((type) => !orgTypes?.includes(type)))
              return null
            return (
              <Tooltip
                key={`menu-${name}`}
                title={item.name}
                enterDelay={0}
                placement="right"
              >
                <ListItem
                  className={classes.menuItem}
                  button
                  component={Link}
                  to={path}
                >
                  <ListItemIcon
                    className={classNames(
                      classes.child,
                      isRouteActive(path) && classes.highlight
                    )}
                  >
                    {icon}
                  </ListItemIcon>
                  <ListItemText
                    disableTypography
                    primary={
                      <Typography
                        className={classNames(
                          classes.child,
                          isRouteActive(path) && classes.highlight
                        )}
                      >
                        {name}
                      </Typography>
                    }
                  />
                </ListItem>
              </Tooltip>
            )
          })}
        </List>
      </Drawer>
      <main className={classes.content} id="content-container">
        {props.children}
      </main>
    </div>
  )
}

const PrivateLayoutWithStyles = withStyles(styles, { withTheme: true })(
  PrivateLayout
)
export default PrivateLayoutWithStyles
