import React, { Suspense, lazy } from 'react'
import { Provider } from 'react-redux'
import PrivateLayout from './common/Layouts/Container'
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  Navigate,
  RouterProvider,
} from 'react-router-dom'

const Dashboard = lazy(() => import('./pages/Dashboard/Dashboard'))
const Orders = lazy(() => import('./pages/Orders/Orders'))
const OrderDetail = lazy(() => import('./pages/Orders/Detail/Container'))
const Login = lazy(() => import('./pages/Login/Container'))
const Logout = lazy(() => import('./pages/Logout/Container'))
const Docks = lazy(() => import('./pages/Docks/Docks'))
const Planning = lazy(() => import('./pages/Planning/Planning'))
const Boats = lazy(() => import('./pages/Boats/Container'))
const BoatDetails = lazy(() => import('./pages/Boats/BoatDetailsContainer'))
const Tracking = lazy(() => import('./pages/Tracking/Container'))
const LimitedAreas = lazy(() => import('./pages/LimitedAreas/LimitedAreas'))
const Users = lazy(() => import('./pages/UsersNew/Container'))
const UserDetail = lazy(() => import('./pages/UsersNew/UserDetailsContainer'))
const Organisations = lazy(() => import('./pages/Organisations/Container'))
const AddOrganisation = lazy(() =>
  import('./pages/Organisations/Add/Container')
)
const OrganisationDetail = lazy(() =>
  import('./pages/Organisations/Detail/Container')
)
const Register = lazy(() => import('./pages/Register/Container'))
const Recover = lazy(() => import('./pages/Recover/Container'))
const Reports = lazy(() => import('./pages/Reports/Container'))
const Snackbar = lazy(() => import('./common/CustomizedSnackbar/Container'))
const InteractiveMapTest = lazy(() =>
  import('./pages/InteractiveMap/test/InteractiveMap')
)
const InteractiveMapV1 = lazy(() =>
  import('./pages/InteractiveMap/v1/InteractiveMap')
)
const InteractiveMapDemo = lazy(() =>
  import('./pages/InteractiveMap/v1/Demo/Checkout')
)

// Driver pages
const Driver = lazy(() => import('./pages/Driver/Container'))
const DriverBoatSelection = lazy(() =>
  import('./pages/Driver/BoatSelection/Container')
)
const DriverRouteSelection = lazy(() =>
  import('./pages/Driver/RouteSelection/Container')
)
const DriverRouteOverview = lazy(() =>
  import('./pages/Driver/RouteOverview/Container')
)
const DriverMap = lazy(() => import('./pages/Driver/MapView/Container'))

// App - Tracker
const AppTracker = lazy(() => import('./apps/tracker/App'))
const AppTrackerSearch = lazy(() =>
  import('./apps/tracker/pages/Search/Search')
)
const AppTrackerPackageTracker = lazy(() =>
  import('./apps/tracker/pages/PackageTracker/PackageTracker')
)
const AppTrackerOrderTracker = lazy(() =>
  import('./apps/tracker/pages/OrderTracker/OrderTracker')
)

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route exact path="/" element={<Navigate to="/home" />} />
      <Route
        exact
        path="/track"
        element={
          <AppTracker>
            <AppTrackerSearch />
          </AppTracker>
        }
      />
      <Route
        exact
        path="/track/package/:id"
        element={
          <AppTracker>
            <AppTrackerPackageTracker />
          </AppTracker>
        }
      />
      <Route
        exact
        path="/track/order/:externalId"
        element={
          <AppTracker>
            <AppTrackerOrderTracker />
          </AppTracker>
        }
      />
      {/* <Route exact path="/track/*" element={<AppTracker />} /> */}
      <Route exact path="/map/v1/demo" element={<InteractiveMapDemo />} />
      <Route exact path="/map/v1" element={<InteractiveMapV1 />} />
      <Route exact path="/map/test" element={<InteractiveMapTest />} />
      <Route
        path="/home"
        element={
          <PrivateLayout>
            <Dashboard />
          </PrivateLayout>
        }
      />
      <Route
        path="/orders"
        element={
          <PrivateLayout>
            <Orders />
          </PrivateLayout>
        }
      />
      <Route
        path="/orders/:orderId"
        element={
          <PrivateLayout>
            <OrderDetail />
          </PrivateLayout>
        }
      />
      <Route
        path="/planning"
        element={
          <PrivateLayout>
            <Planning />
          </PrivateLayout>
        }
      />
      <Route
        path="/docks"
        element={
          <PrivateLayout>
            <Docks />
          </PrivateLayout>
        }
      />
      <Route path="/boats">
        <Route
          path="/boats"
          element={
            <PrivateLayout>
              <Boats />
            </PrivateLayout>
          }
        />

        <Route
          path="/boats/:boatId"
          element={
            <PrivateLayout>
              <BoatDetails />
            </PrivateLayout>
          }
        />
      </Route>
      <Route
        path="/tracking"
        element={
          <PrivateLayout>
            <Tracking />
          </PrivateLayout>
        }
      />
      <Route path="/users">
        <Route
          path="/users"
          element={
            <PrivateLayout>
              <Users />
            </PrivateLayout>
          }
        />
        <Route
          path="/users/:organisationId/:userId"
          element={
            <PrivateLayout>
              <UserDetail />
            </PrivateLayout>
          }
        />
      </Route>
      <Route
        path="/organisations"
        element={
          <PrivateLayout>
            <Organisations />
          </PrivateLayout>
        }
      />
      <Route
        path="/organisations/add"
        element={
          <PrivateLayout>
            <AddOrganisation />
          </PrivateLayout>
        }
      />
      <Route
        path="/organisations/:id"
        element={
          <PrivateLayout>
            <OrganisationDetail />
          </PrivateLayout>
        }
      />
      <Route
        path="/limited-areas"
        element={
          <PrivateLayout>
            <LimitedAreas />
          </PrivateLayout>
        }
      />
      <Route
        path="/reports"
        element={
          <PrivateLayout>
            <Reports />
          </PrivateLayout>
        }
      />
      <Route exact path="/register/:token" Component={Register} />
      <Route exact path="/recover/:token" Component={Recover} />
      <Route exact path="/driver" Component={Driver} />
      <Route exact path="/driver/boats" Component={DriverBoatSelection} />
      <Route
        exact
        path="/driver/boats/:boatId/routes"
        Component={DriverRouteSelection}
      />
      <Route
        exact
        path="/driver/boats/:boatId/routes/:routeId"
        Component={DriverRouteOverview}
      />
      <Route
        exact
        path="/driver/boats/:boatId/routes/:routeId/map"
        Component={DriverMap}
      />
      <Route exact path="/login" Component={Login} />
      {/* <Route exact path="/logout" Component={Logout} /> */}
      <Route exact path="/logout" element={<Logout />} />
    </>
  )
)

// import Dashboard from './pages/Dashboard/Dashboard'
// import Login from './pages/Login/Container'
// import Logout from './pages/Logout/Container'
// import Orders from './pages/Orders/Orders'
// import OrderDetail from './pages/Orders/Detail/Container'
// import Docks from './pages/Docks/Docks'
// import Planning from './pages/Planning/Planning'
// import Boats from './pages/Boats/Boats'
// import Driver from './pages/Driver/Container'
// import LimitedAreas from './pages/LimitedAreas/LimitedAreas'
// import Users from './pages/Users/Container'
// import Organisations from './pages/Organisations/Container'
// import AddOrganisation from './pages/Organisations/Add/Container'
// import OrganisationDetail from './pages/Organisations/Detail/Container'
// import InteractiveMapV1 from './pages/InteractiveMap/v1/InteractiveMap'
// import InteractiveMapDemo from './pages/InteractiveMap/v1/Demo/Checkout'
// // import AddUser from './pages/Users/Add/Container'
// import UserDetail from './pages/Users/Detail/Container'
// import Register from './pages/Register/Container'
// import Recover from './pages/Recover/Container'
// import Reports from './pages/Reports/Container'
// import Snackbar from './common/CustomizedSnackbar/Container'

const Router = ({ store }) => (
  <Provider store={store}>
    <Suspense
      fallback={
        <div
          style={{
            position: 'absolute',
            left: '50%',
            top: '50%',
            transform: 'translate(-50%, -50%)',
            fontFamily: 'sans-serif',
            padding: 10,
            color: '#555',
            fontSize: 11,
          }}
        >
          Loading...
        </div>
      }
    >
      <Snackbar />
      <RouterProvider router={router} />
    </Suspense>
  </Provider>
)

export default Router
