import React, { useEffect, useState } from 'react'
import { TransitionGroup } from 'react-transition-group'
import Slide from '@material-ui/core/Slide'
import ReactDOM from 'react-dom'
import injectSheet from 'react-jss'
import clsx from 'clsx'
import { createRoot } from 'react-dom/client'

const containerStyles = {
  root: {
    position: 'fixed',
    zIndex: 2000,
    top: 100,
    right: 20,
  },
}

const ErrorIcon =
  '<svg aria-hidden="true" height="16" width="12" viewBox="0 0 12 16" class="react-toast-notifications__toast__icon css-rqgsqp" style="display: inline-block; vertical-align: text-top; fill: currentcolor;"><path fill-rule="evenodd" d="M5.05.01c.81 2.17.41 3.38-.52 4.31C3.55 5.37 1.98 6.15.9 7.68c-1.45 2.05-1.7 6.53 3.53 7.7-2.2-1.16-2.67-4.52-.3-6.61-.61 2.03.53 3.33 1.94 2.86 1.39-.47 2.3.53 2.27 1.67-.02.78-.31 1.44-1.13 1.81 3.42-.59 4.78-3.42 4.78-5.56 0-2.84-2.53-3.22-1.25-5.61-1.52.13-2.03 1.13-1.89 2.75.09 1.08-1.02 1.8-1.86 1.33-.67-.41-.66-1.19-.06-1.78C8.18 5.01 8.68 2.15 5.05.02L5.03 0l.02.01z"></path></svg>'
const SuccessIcon =
  '<svg aria-hidden="true" height="16" width="12" viewBox="0 0 12 16" class="react-toast-notifications__toast__icon css-rqgsqp" style="display: inline-block; vertical-align: text-top; fill: currentcolor;"><path fill-rule="evenodd" d="M12 5.5l-8 8-4-4L1.5 8 4 10.5 10.5 4 12 5.5z"></path></svg>'
const InfoIcon =
  '<svg aria-hidden="true" height="16" width="14" viewBox="0 0 14 16" class="react-toast-notifications__toast__icon css-rqgsqp" style="display: inline-block; vertical-align: text-top; fill: currentcolor;"><path fill-rule="evenodd" d="M6.3 5.71a.942.942 0 0 1-.28-.7c0-.28.09-.52.28-.7.19-.18.42-.28.7-.28.28 0 .52.09.7.28.18.19.28.42.28.7 0 .28-.09.52-.28.7a1 1 0 0 1-.7.3c-.28 0-.52-.11-.7-.3zM8 8.01c-.02-.25-.11-.48-.31-.69-.2-.19-.42-.3-.69-.31H6c-.27.02-.48.13-.69.31-.2.2-.3.44-.31.69h1v3c.02.27.11.5.31.69.2.2.42.31.69.31h1c.27 0 .48-.11.69-.31.2-.19.3-.42.31-.69H8V8v.01zM7 2.32C3.86 2.32 1.3 4.86 1.3 8c0 3.14 2.56 5.7 5.7 5.7s5.7-2.55 5.7-5.7c0-3.15-2.56-5.69-5.7-5.69v.01zM7 1c3.86 0 7 3.14 7 7s-3.14 7-7 7-7-3.12-7-7 3.14-7 7-7z"></path></svg>'
const WarningIcon =
  '<path fill-rule="evenodd" d="M8.893 1.5c-.183-.31-.52-.5-.887-.5s-.703.19-.886.5L.138 13.499a.98.98 0 0 0 0 1.001c.193.31.53.501.886.501h13.964c.367 0 .704-.19.877-.5a1.03 1.03 0 0 0 .01-1.002L8.893 1.5zm.133 11.497H6.987v-2.003h2.039v2.003zm0-3.004H6.987V5.987h2.039v4.006z"></path>'
const CloseIcon =
  '<svg aria-hidden="true" height="16" width="14" viewBox="0 0 14 16" class="react-toast-notifications__toast__dismiss-icon" style="display: inline-block; vertical-align: text-top; fill: currentcolor;"><path fill-rule="evenodd" d="M7.71 8.23l3.75 3.75-1.48 1.48-3.75-3.75-3.75 3.75L1 11.98l3.75-3.75L1 4.48 2.48 3l3.75 3.75L9.98 3l1.48 1.48-3.75 3.75z"></path></svg>'

const NotificationContainer = injectSheet(containerStyles)(
  ({ classes, ...rest }) => <div className={classes.root} {...rest} />
)

const Types = {
  SUCCESS: 'SUCCESS',
  WARNING: 'WARNING',
  ERROR: 'ERROR',
  INFO: 'INFO',
}

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'stretch',
    borderRadius: 6,
    overflow: 'hidden',
    border: '0px solid #000',
    boxShadow: 'rgb(0 0 0 / 18%) 0px 3px 8px',
    fontSize: 14,
    marginBottom: 8,
    maxWidth: 300,
    transition: '1s',
    transform: 'translateX(100%)',
    animation: '$slide 0.5s forwards',
  },
  '@keyframes slide': {
    from: { transform: 'translateX(100%)' },
    to: { transform: 'translateX(0)' },
  },
  iconContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 40,
    padding: 12,
    '& svg': {
      width: 16,
      height: 16,
      color: '#fff',
    },
  },
  closeContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 40,
    padding: 12,
    color: 'rgba(0,0,0,0.3)',
    '&:hover': {
      color: 'rgba(0,0,0,0.6)',
      cursor: 'pointer',
    },
  },
  iconContainerERROR: {
    backgroundColor: 'rgb(255, 86, 48)',
  },
  iconContainerWARNING: {
    backgroundColor: 'rgb(255, 171, 0)',
  },
  iconContainerSUCCESS: {
    backgroundColor: 'rgb(54, 179, 126)',
  },
  iconContainerINFO: {
    backgroundColor: 'rgb(38, 132, 255)',
  },
  textContainer: {
    padding: 12,
    wordBreak: 'break-word',
    flexGrow: 1,
  },
  [Types.SUCCESS]: {
    backgroundColor: 'rgb(227, 252, 239)',
    color: 'rgb(0, 102, 68)',
  },
  [Types.WARNING]: {
    backgroundColor: 'rgb(255, 250, 230)',
    color: 'rgb(255, 139, 0)',
  },
  [Types.ERROR]: {
    backgroundColor: 'rgb(255, 235, 230)',
    color: 'rgb(191, 38, 0)',
  },
  [Types.INFO]: {
    backgroundColor: '#fff',
    color: 'rgb(80, 95, 121)',
  },
}

const Notification = injectSheet(styles)(
  ({ classes, message, type, onClose, ...rest }) => {
    let icon
    if (type === Types.ERROR) {
      icon = ErrorIcon
    } else if (type === Types.SUCCESS) {
      icon = SuccessIcon
    } else if (type === Types.WARNING) {
      icon = WarningIcon
    }

    return (
      <div className={clsx(classes.root, classes[type])}>
        <div
          className={clsx(
            classes.iconContainer,
            classes[`iconContainer${type}`]
          )}
          dangerouslySetInnerHTML={{ __html: icon }}
        />
        <div className={classes.textContainer}>
          <span>{message}</span>
        </div>
        <div
          className={classes.closeContainer}
          dangerouslySetInnerHTML={{ __html: CloseIcon }}
          onClick={onClose}
        />
      </div>
    )
  }
)

let list = []

let _root = null
const notification = (type, message, time, id) => {
  if (id) {
    if (id === 'CLEAR') {
      list = []
    } else {
      list = list.filter((el) => el.props.id !== id)
    }
  }
  if (type) {
    if (list.length >= 3) {
      list.splice(0, 1)
    }
    const key = id || `${new Date().getTime()}`
    const notificationElement = (
      <Notification
        type={type}
        message={message}
        key={key}
        id={key}
        onClose={() => notification(null, null, null, key)}
      />
    )
    list.push(notificationElement)
    setTimeout(() => {
      notification(null, null, null, id)
    }, time)
  }

  const containerElement = <NotificationContainer>{list}</NotificationContainer>
  if (!_root) {
    const container = document.getElementById('notifications')
    _root = createRoot(container)
  }
  _root.render(containerElement)
  // ReactDOM.render(containerElement, document.getElementById('notifications'))
}

const success = (message, id, time = 3000) =>
  notification(Types.SUCCESS, message, time, id)
const warning = (message, id, time = 6000) =>
  notification(Types.WARNING, message, time, id)
const error = (message, id, time = 8000) =>
  notification(Types.ERROR, message, time, id)
const clear = () => {
  notification(null, null, null, 'CLEAR')
}

const Notifications = {
  success,
  warning,
  error,
  clear,
}
export default Notifications
