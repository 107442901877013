export const Roles = {
  ADMIN: 'ADMIN',
  SUBCONTRACTOR_ADMIN: 'SUBCONTRACTOR_ADMIN',
  SUBCONTRACTOR_USER: 'SUBCONTRACTOR_USER',
  PARTNER_ADMIN: 'PARTNER_ADMIN',
  PARTNER_USER: 'PARTNER_USER',
  DRIVER: 'DRIVER',
}

export const OrganisationTypes = ['INTERNAL', 'PARTNER', 'SUBCONTRACTOR']
export const OrganisationTypeRoles = {
  INTERNAL: Object.values(Roles),
  PARTNER: [Roles.PARTNER_ADMIN, Roles.PARTNER_USER],
  SUBCONTRACTOR: [
    Roles.SUBCONTRACTOR_ADMIN,
    Roles.SUBCONTRACTOR_USER,
    Roles.DRIVER,
  ],
}
export const OrganisationTypesObject = {
  INTERNAL: 'INTERNAL',
  PARTNER: 'PARTNER',
  SUBCONTRACTOR: 'SUBCONTRACTOR',
}

export const isAdmin = (user) => {
  if (!user || !Array.isArray(user.roles)) return false
  return user.roles.some((role) => role === Roles.ADMIN)
}

export const rolesMatch = (user, requiredRoles = []) => {
  if (user && Array.isArray(user.roles)) {
    for (let i = 0; i < user.roles.length; i += 1) {
      const userRole = user.roles[i]
      for (let j = 0; j < requiredRoles.length; j += 1) {
        const requiredRole = requiredRoles[j]
        if (userRole === requiredRole) {
          return true
        }
      }
    }
  }
  return false
}

export const validRolesToAdd = (user) => {
  const availableRolesPerType = {
    INTERNAL: Object.keys(Roles).map((key) => key),
    PARTNER: [Roles.PARTNER_ADMIN, Roles.PARTNER_USER],
    SUBCONTRACTOR: [
      Roles.SUBCONTRACTOR_ADMIN,
      Roles.SUBCONTRACTOR_USER,
      Roles.DRIVER,
    ],
  }

  const loggedInUser = JSON.parse(localStorage.getItem('user'))

  const organisationTypes =
    user.organisation && user.organisation.organisationTypes.map((t) => t.type)
  let accessibleRoles = []
  // organisationTypes.forEach(type => {
  //   const roles = availableRolesPerType[type]
  //   roles.forEach(role => {
  //     if (!accessibleRoles.includes(role)) accessibleRoles.push(role)
  //   })
  // })

  const isInternalUser =
    loggedInUser.roles && loggedInUser.roles.includes('ADMIN')

  const internalType = organisationTypes.includes('INTERNAL')
  const subcontractorType =
    organisationTypes.includes('SUBCONTRACTOR') && !internalType
  const partnerType = organisationTypes.includes('PARTNER') && !internalType

  if (internalType && isInternalUser)
    accessibleRoles = availableRolesPerType.INTERNAL
  if (subcontractorType) accessibleRoles = availableRolesPerType.SUBCONTRACTOR
  if (partnerType)
    accessibleRoles = [...accessibleRoles, ...availableRolesPerType.PARTNER]

  for (let i = 0; i < user.roles.length; i += 1) {
    if (accessibleRoles.includes(user.roles[i])) {
      const idx = accessibleRoles.indexOf(user.roles[i])
      if (idx !== -1) accessibleRoles.splice(idx, 1)
    }
  }
  return accessibleRoles.map((role) => ({ value: role, label: role }))
}

export const canManageOrganisations = (user) => rolesMatch(user, [Roles.ADMIN])
export const canEditOrganisation = (user) =>
  rolesMatch(user, [
    Roles.ADMIN,
    Roles.PARTNER_ADMIN,
    Roles.SUBCONTRACTOR_ADMIN,
  ])

export const canManageUsers = (user) =>
  rolesMatch(user, [
    Roles.ADMIN,
    Roles.PARTNER_ADMIN,
    Roles.SUBCONTRACTOR_ADMIN,
  ])
export const canViewUsers = (user) => rolesMatch(user, Object.values(Roles)) // all roles
export const canEditUser = (user) => rolesMatch(user, Object.values(Roles)) // all roles

export const isAccessibleForUser = (user, roles) => rolesMatch(user, roles)
