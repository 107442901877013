import React from 'react'
import injectSheet from 'react-jss'

const styles = {
  root: {
    zIndex: 1205,
    backgroundColor: 'rgba(0,0,0,0.75)',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  modal: {
    position: 'absolute',
    transform: 'translate(-50%, -50%)',
    top: '50%',
    left: '50%',
    backgroundColor: '#fff',
    borderRadius: 6,
    minWidth: '30%',
    maxWidth: 500,
    maxHeight: '90%',
    overflow: 'scroll',
  },
  title: {
    padding: 16,
    textAlign: 'center',
    color: '#fff',
    fontSize: '1.25rem',
    fontWeight: 400,
    backgroundColor: '#37A1FD',
    boxShadow:
      '0 0px 1px -2px rgb(0 0 0 / 20%), 0 1px 3px 0 rgb(0 0 0 / 14%), 0 0px 1px 0 rgb(0 0 0 / 12%)',
  },
  // title: {
  //   // fontSize: 20,
  //   // fontWeight: 400,
  //   color: 'var(--color-text)',
  //   borderBottom: '1px solid #ccc',
  //   textAlign: 'left',
  //   fontWeight: 300,
  //   fontSize: 16,
  //   padding: 24,
  //   margin: 0
  // },
  body: {
    position: 'relative',
    padding: 24,
  },
  buttonContainer: {
    display: 'flex',
    padding: 24,
    flexDirection: 'row',
    justifyContent: 'flex-end',
    borderTop: '1px solid #eee',
  },

  '@media (max-width: 640px)': {
    modal: {
      borderRadius: 0,
      border: 'none',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      minWidth: 'auto',
      maxWidth: 'auto',
      maxHeight: '100%',
      width: '100%',
      height: '100%',
      transform: 'translate(0,0)',
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
    },
    body: {
      display: 'flex',
      flexGrow: 1,
    },
    title: {
      textAlign: 'center',
      color: '#fff',
      paddingTop: 'max(env(safe-area-inset-top, 18px), 18px)',
      fontWeight: 500,
      backgroundColor: '#37A1FD',
      boxShadow:
        '0 0px 1px -2px rgb(0 0 0 / 20%), 0 1px 3px 0 rgb(0 0 0 / 14%), 0 0px 1px 0 rgb(0 0 0 / 12%)',
    },
    buttonContainer: {
      justifyContent: 'center',
    },
  },
}

const Modal = (props) => {
  const { classes, children, title, onClose, buttons, open } = props

  if (!open) return null
  return (
    <div className={classes.root}>
      <div className={classes.modal}>
        <h2 className={classes.title}>{title || ''}</h2>
        <div className={classes.body}>{children}</div>
        {buttons && <div className={classes.buttonContainer}>{buttons}</div>}
      </div>
    </div>
  )
}

export default injectSheet(styles)(Modal)
