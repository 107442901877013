import axios from 'axios'
import { setSnackbar } from './snackbar'
import { replace } from '../../helpers/immutable'

const initialState = {
  list: [],
  dock: null,
  isLoadingFetch: false,
  isLoadingCreateOrUpdate: false,
  isLoadingRemove: false,
  isCreatingOrEditing: false,
}

/* HANDLE DOCK PROPERTIES */
const HANDLE_NAME_CHANGE = 'docks/HANDLE_NAME_CHANGE'
export const handleNameChange = (input) => ({
  type: HANDLE_NAME_CHANGE,
  payload: input,
})

const HANDLE_ADDRESS_CHANGE = 'docks/HANDLE_ADDRESS_CHANGE'
export const handleAddressChange = (input) => ({
  type: HANDLE_ADDRESS_CHANGE,
  payload: input,
})

const HANDLE_LATITUDE_CHANGE = 'docks/HANDLE_LATITUDE_CHANGE'
export const handleLatitudeChange = (input) => ({
  type: HANDLE_LATITUDE_CHANGE,
  payload: input,
})

const HANDLE_LONGITUDE_CHANGE = 'docks/HANDLE_LONGITUDE_CHANGE'
export const handleLongitudeChange = (input) => ({
  type: HANDLE_LONGITUDE_CHANGE,
  payload: input,
})

const TOGGLE_CREATE_OR_EDIT = 'docks/TOGGLE_CREATE_OR_EDIT'
export const toggleCreateOrEdit = (dock) => ({
  type: TOGGLE_CREATE_OR_EDIT,
  payload: dock,
})

const REMOVE_DOCK_START = 'REMOVE_DOCK_START'
const removeDockStart = () => ({
  type: REMOVE_DOCK_START,
})

const REMOVE_DOCK_FAIL = 'REMOVE_DOCK_FAIL'
const removeDockFail = () => ({
  type: REMOVE_DOCK_FAIL,
})

const REMOVE_DOCK_SUCCESS = 'REMOVE_DOCK_SUCCESS'
const removeDockSuccess = (dockId) => ({
  type: REMOVE_DOCK_SUCCESS,
  payload: dockId,
})

export const remove = (dockId) => async (dispatch) => {
  dispatch(removeDockStart())
  try {
    await axios.delete(`/admin/docks/${dockId}`)
    dispatch(removeDockSuccess(dockId))
  } catch (e) {
    dispatch(removeDockFail())
  }
}

const CREATE_DOCK_START = 'CREATE_DOCK_START'
const createDockStart = () => ({
  type: CREATE_DOCK_START,
})

const CREATE_DOCK_FAIL = 'CREATE_DOCK_FAIL'
const createDockFail = () => ({
  type: CREATE_DOCK_FAIL,
})

const CREATE_DOCK_SUCCESS = 'CREATE_DOCK_SUCCESS'
const createDockSuccess = (dock) => ({
  type: CREATE_DOCK_SUCCESS,
  payload: dock,
})

export const create = () => async (dispatch, getState) => {
  const { dock } = getState().docks
  dispatch(createDockStart())
  try {
    const { data } = await axios.post('/admin/docks', dock)
    dispatch(createDockSuccess(data))
  } catch (e) {
    dispatch(createDockFail())
  }
}

const UPDATE_DOCK_START = 'UPDATE_DOCK_START'
const updateDockStart = () => ({
  type: UPDATE_DOCK_START,
})

const UPDATE_DOCK_FAIL = 'UPDATE_DOCK_FAIL'
const updateDockFail = () => ({
  type: UPDATE_DOCK_FAIL,
})

const UPDATE_DOCK_SUCCESS = 'UPDATE_DOCK_SUCCESS'
const updateDockSuccess = (dock) => ({
  type: UPDATE_DOCK_SUCCESS,
  payload: dock,
})

export const update = () => async (dispatch, getState) => {
  const { dock } = getState().docks
  dispatch(updateDockStart())
  try {
    const { data } = await axios.patch(`/admin/docks/${dock.id}`, dock)
    dispatch(updateDockSuccess(data))
  } catch (e) {
    dispatch(updateDockFail())
  }
}

const FETCH_DOCKS_START = 'FETCH_DOCKS_START'
const fetchDocksStart = () => ({
  type: FETCH_DOCKS_START,
})

const FETCH_DOCKS_FAIL = 'FETCH_DOCKS_FAIL'
const fetchDocksFail = () => ({
  type: FETCH_DOCKS_FAIL,
})

const FETCH_DOCKS_SUCCESS = 'FETCH_DOCKS_SUCCESS'
const fetchDocksSuccess = (docks) => ({
  type: FETCH_DOCKS_SUCCESS,
  payload: docks,
})

export const fetchAll = () => async (dispatch) => {
  dispatch(fetchDocksStart())
  try {
    const { data } = await axios.get('/admin/docks')
    dispatch(fetchDocksSuccess(data))
  } catch (e) {
    dispatch(fetchDocksFail())
    dispatch(setSnackbar('Ett oväntat fel uppstod', 'error'))
  }
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case HANDLE_NAME_CHANGE:
      return { ...state, dock: { ...state.dock, name: action.payload } }
    case HANDLE_ADDRESS_CHANGE:
      return { ...state, dock: { ...state.dock, address: action.payload } }
    case HANDLE_LONGITUDE_CHANGE:
      return { ...state, dock: { ...state.dock, lng: action.payload } }
    case HANDLE_LATITUDE_CHANGE:
      return { ...state, dock: { ...state.dock, lat: action.payload } }
    case TOGGLE_CREATE_OR_EDIT:
      return {
        ...state,
        dock: action.payload,
        isCreatingOrEditing: !state.isCreatingOrEditing,
      }
    case FETCH_DOCKS_START:
      return { ...state, isLoadingFetch: true }
    case FETCH_DOCKS_FAIL:
      return { ...state, isLoadingFetch: false }
    case FETCH_DOCKS_SUCCESS:
      return { ...state, isLoadingFetch: false, list: action.payload }
    case CREATE_DOCK_START:
      return { ...state, isLoadingCreateOrUpdate: true }
    case CREATE_DOCK_FAIL:
      return { ...state, isLoadingCreateOrUpdate: false }
    case CREATE_DOCK_SUCCESS:
      return {
        ...state,
        isLoadingCreateOrUpdate: false,
        list: [...state.list, action.payload],
        isCreatingOrEditing: false,
        dock: null,
      }
    case UPDATE_DOCK_START:
      return { ...state, isLoadingCreateOrUpdate: true }
    case UPDATE_DOCK_FAIL:
      return { ...state, isLoadingCreateOrUpdate: false }
    case UPDATE_DOCK_SUCCESS: {
      const list = replace(
        { id: action.payload.id },
        action.payload,
        state.list
      )
      return {
        ...state,
        isLoadingCreateOrUpdate: false,
        list,
        isCreatingOrEditing: false,
        dock: null,
      }
    }
    case REMOVE_DOCK_START:
      return { ...state, isLoadingRemove: true }
    case REMOVE_DOCK_FAIL:
      return { ...state, isLoadingRemove: false }
    case REMOVE_DOCK_SUCCESS: {
      const list = replace({ id: action.payload }, null, state.list)
      return { ...state, isLoadingRemove: false, list }
    }
    default:
      return state
  }
}

export default reducer
