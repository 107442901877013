import axios from 'axios'
import { setSnackbar } from './snackbar'
import { replace } from '../../helpers/immutable'

const initialState = {
  list: [],
  boat: null,
  isLoadingFetch: false,
  isLoadingCreateOrUpdate: false,
  isLoadingRemove: false,
  isCreatingOrEditing: false,
}

const HANDLE_CHANGE_NAME = 'boats/HANDLE_CHANGE_NAME'
export const handleChangeName = (name) => ({
  type: HANDLE_CHANGE_NAME,
  payload: name,
})

const HANDLE_CHANGE_MAX_CRATES = 'boats/HANDLE_CHANGE_MAX_CRATES'
export const handleChangeMaxCrates = (maxCrates) => ({
  type: HANDLE_CHANGE_MAX_CRATES,
  payload: maxCrates,
})

const HANDLE_CHANGE_MAX_WEIGHT = 'boats/HANDLE_CHANGE_MAX_WEIGHT'
export const handleChangeMaxWeight = (maxWeight) => ({
  type: HANDLE_CHANGE_MAX_WEIGHT,
  payload: maxWeight,
})

const HANDLE_CHANGE_MAX_SPEED = 'boats/HANDLE_CHANGE_MAX_SPEED'
export const handleChangeMaxSpeed = (maxSpeed) => ({
  type: HANDLE_CHANGE_MAX_SPEED,
  payload: maxSpeed,
})

const HANDLE_CHANGE_STOP_TIME = 'boats/HANDLE_CHANGE_STOP_TIME'
export const handleChangeStopTime = (stopTime) => ({
  type: HANDLE_CHANGE_STOP_TIME,
  payload: stopTime,
})

const HANDLE_CHANGE_TRACKTOR_ID = 'boats/HANDLE_CHANGE_TRACKTOR_ID'
export const handleChangeTracktorId = (tracktorId) => ({
  type: HANDLE_CHANGE_TRACKTOR_ID,
  payload: tracktorId,
})

/* TOGGLE MODAL */
const TOGGLE_CREATE_OR_UPDATE = 'TOGGLE_CREATE_OR_UPDATE'
export const toggleCreateOrEdit = (boat) => ({
  type: TOGGLE_CREATE_OR_UPDATE,
  payload: boat,
})

/* FETCH BOATS */
const FETCH_BOATS_START = 'FETCH_BOATS_START'
const fetchBoatsStart = () => ({
  type: FETCH_BOATS_START,
})

const FETCH_BOATS_FAIL = 'FETCH_BOATS_FAIL'
const fetchBoatsFail = () => ({
  type: FETCH_BOATS_FAIL,
})

const FETCH_BOATS_SUCCESS = 'FETCH_BOATS_SUCCESS'
const fetchBoatsSuccess = (boatList) => ({
  type: FETCH_BOATS_SUCCESS,
  payload: boatList,
})

export const fetchAll = () => async (dispatch) => {
  dispatch(fetchBoatsStart())
  try {
    const { data } = await axios.get('/admin/boats')
    dispatch(fetchBoatsSuccess(data))
  } catch (e) {
    dispatch(fetchBoatsFail())
    dispatch(setSnackbar('Ett oväntad fel uppstod', 'error'))
  }
}

/* CREATE BOATS */
const CREATE_BOAT_START = 'CREATE_BOAT_START'
const createBoatStart = () => ({
  type: CREATE_BOAT_START,
})

const CREATE_BOAT_FAIL = 'CREATE_BOAT_FAIL'
const createBoatFail = () => ({
  type: CREATE_BOAT_FAIL,
})

const CREATE_BOAT_SUCCESS = 'CREATE_BOAT_SUCCESS'
const createBoatSuccess = (boat) => ({
  type: CREATE_BOAT_SUCCESS,
  payload: boat,
})

export const create = () => async (dispatch, getState) => {
  const { boat } = getState().boats
  dispatch(createBoatStart())
  try {
    const { data } = await axios.post('/admin/boats', boat)
    dispatch(createBoatSuccess(data))
  } catch (e) {
    dispatch(createBoatFail())
    dispatch(setSnackbar('Gick inte att skapa båt', 'error'))
  }
}

/* UPDATE BOATS */
const UPDATE_BOAT_START = 'UPDATE_BOAT_START'
const updateBoatStart = () => ({
  type: UPDATE_BOAT_START,
})

const UPDATE_BOAT_FAIL = 'UPDATE_BOAT_FAIL'
const updateBoatFail = () => ({
  type: UPDATE_BOAT_FAIL,
})

const UPDATE_BOAT_SUCCESS = 'UPDATE_BOAT_SUCCESS'
const updateBoatSuccess = (boat) => ({
  type: UPDATE_BOAT_SUCCESS,
  payload: boat,
})

export const update = () => async (dispatch, getState) => {
  const { boat } = getState().boats
  dispatch(updateBoatStart())
  try {
    const { data } = await axios.patch(`/admin/boats/${boat.id}`, boat)
    dispatch(updateBoatSuccess(data))
  } catch (e) {
    dispatch(updateBoatFail())
    dispatch(setSnackbar('Ett oväntad fel uppstod', 'error'))
  }
}

const REMOVE_BOAT_START = 'REMOVE_BOAT_START'
const removeBoatStart = () => ({
  type: REMOVE_BOAT_START,
})

const REMOVE_BOAT_FAIL = 'REMOVE_BOAT_FAIL'
const removeBoatFail = () => ({
  type: REMOVE_BOAT_FAIL,
})

const REMOVE_BOAT_SUCCESS = 'REMOVE_BOAT_SUCCESS'
const removeBoatSuccess = (boat) => ({
  type: REMOVE_BOAT_SUCCESS,
  payload: boat,
})

export const remove = (boat) => async (dispatch) => {
  dispatch(removeBoatStart())
  try {
    await axios.delete(`/admin/boats/${boat.id}`)
    dispatch(removeBoatSuccess(boat))
  } catch (e) {
    dispatch(removeBoatFail())
    dispatch(setSnackbar('Gick inte att radera båt', 'error'))
  }
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case HANDLE_CHANGE_NAME:
      return { ...state, boat: { ...state.boat, name: action.payload } }
    case HANDLE_CHANGE_MAX_CRATES:
      return { ...state, boat: { ...state.boat, maxCrates: action.payload } }
    case HANDLE_CHANGE_MAX_SPEED:
      return { ...state, boat: { ...state.boat, maxSpeed: action.payload } }
    case HANDLE_CHANGE_MAX_WEIGHT:
      return { ...state, boat: { ...state.boat, maxWeight: action.payload } }
    case HANDLE_CHANGE_STOP_TIME:
      return { ...state, boat: { ...state.boat, stopTime: action.payload } }
    case HANDLE_CHANGE_TRACKTOR_ID:
      return { ...state, boat: { ...state.boat, tracktorId: action.payload } }
    case TOGGLE_CREATE_OR_UPDATE:
      return {
        ...state,
        boat: action.payload,
        isCreatingOrEditing: !state.isCreatingOrEditing,
      }
    case FETCH_BOATS_START:
      return { ...state, isLoadingFetch: true }
    case FETCH_BOATS_FAIL:
      return { ...state, isLoadingFetch: false }
    case FETCH_BOATS_SUCCESS:
      return { ...state, isLoadingFetch: false, list: action.payload }
    case CREATE_BOAT_START:
      return { ...state, isLoadingCreateOrUpdate: true }
    case CREATE_BOAT_FAIL:
      return { ...state, isLoadingCreateOrUpdate: false }
    case CREATE_BOAT_SUCCESS: {
      const list = [...state.list]
      list.push(action.payload)
      return {
        ...state,
        isLoadingCreateOrUpdate: false,
        list,
        boat: null,
        isCreatingOrEditing: false,
      }
    }
    case UPDATE_BOAT_START:
      return { ...state, isLoadingCreateOrUpdate: true }
    case UPDATE_BOAT_FAIL:
      return { ...state, isLoadingCreateOrUpdate: false }
    case UPDATE_BOAT_SUCCESS: {
      const list = replace(
        { id: action.payload.id },
        action.payload,
        state.list
      )
      return {
        ...state,
        isLoadingCreateOrUpdate: false,
        list,
        boat: null,
        isCreatingOrEditing: false,
      }
    }
    case REMOVE_BOAT_START:
      return { ...state, isLoadingRemove: true }
    case REMOVE_BOAT_FAIL:
      return { ...state, isLoadingRemove: false }
    case REMOVE_BOAT_SUCCESS: {
      const list = replace({ id: action.payload.id }, null, state.list)
      return {
        ...state,
        isLoadingRemove: false,
        list,
        boat: null,
        isCreatingOrEditing: false,
      }
    }
    default:
      return state
  }
}

export default reducer
