import axios from 'axios'
import { setSnackbar } from './snackbar'
import { replace } from '../../helpers/immutable'

const initialState = {
  list: [],
  limitedArea: null,
  isLoadingFetch: false,
  isLoadingRemove: false,
  isLoadingCreateOrUpdate: false,
  isCreatingOrEditing: false,
}

/* HANDLE LIMITED AREA CHANGE */
const HANDLE_NAME_CHANGE = 'limitedAreas/HANDLE_NAME_CHANGE'
export const handleNameChange = (name) => ({
  type: HANDLE_NAME_CHANGE,
  payload: name,
})

const HANDLE_DISTANCE_CHANGE = 'limitedAreas/HANDLE_DISTANCE_CHANGE'
export const handleDistanceChange = (distance) => ({
  type: HANDLE_DISTANCE_CHANGE,
  payload: distance,
})

const HANDLE_SPEED_LIMIT_CHANGE = 'limitedAreas/HANDLE_SPEED_LIMIT_CHANGE'
export const handleSpeedLimitChange = (maxSpeed) => ({
  type: HANDLE_SPEED_LIMIT_CHANGE,
  payload: maxSpeed,
})

/* TOGGLE INPUT FIELDS */
const TOGGLE_CREATE_OR_EDIT = 'limitedAreas/TOGGLE_CREATE_OR_EDIT'
export const toggleCreateOrEdit = (limitedArea) => ({
  type: TOGGLE_CREATE_OR_EDIT,
  payload: limitedArea,
})

/* CREATE LIMITED AREA */
const CREATE_LIMITED_AREA_START = 'CREATE_LIMITED_AREA_START'
const createLimitedAreaStart = () => ({
  type: CREATE_LIMITED_AREA_START,
})

const CREATE_LIMITED_AREA_FAIL = 'CREATE_LIMITED_AREA_FAIL'
const createLimitedAreaFail = () => ({
  type: CREATE_LIMITED_AREA_FAIL,
})

const CREATE_LIMITED_AREA_SUCCESS = 'CREATE_LIMITED_AREA_SUCCESS'
const createLimitedAreaSuccess = (limitedArea) => ({
  type: CREATE_LIMITED_AREA_SUCCESS,
  payload: limitedArea,
})

export const create = () => async (dispatch, getState) => {
  const { limitedArea } = getState().limitedAreas
  dispatch(createLimitedAreaStart())
  try {
    const { data } = await axios.post('/admin/limitedareas', limitedArea)
    dispatch(createLimitedAreaSuccess(data))
  } catch (e) {
    dispatch(createLimitedAreaFail())
  }
}

/* UPDATE LIMITED AREA */
const UPDATE_LIMITED_AREA_START = 'UPDATE_LIMITED_AREA_START'
const updateLimitedAreaStart = () => ({
  type: UPDATE_LIMITED_AREA_START,
})

const UPDATE_LIMITED_AREA_FAIL = 'UPDATE_LIMITED_AREA_FAIL'
const updateLimitedAreaFail = () => ({
  type: UPDATE_LIMITED_AREA_FAIL,
})

const UPDATE_LIMITED_AREA_SUCCESS = 'UPDATE_LIMITED_AREA_SUCCESS'
const updateLimitedAreaSuccess = (limitedArea) => ({
  type: UPDATE_LIMITED_AREA_SUCCESS,
  payload: limitedArea,
})

export const update = () => async (dispatch, getState) => {
  const { limitedArea } = getState().limitedAreas
  dispatch(updateLimitedAreaStart())
  try {
    await axios.patch(`/admin/limitedareas/${limitedArea.id}`, limitedArea)
    dispatch(updateLimitedAreaSuccess(limitedArea))
  } catch (e) {
    dispatch(updateLimitedAreaFail())
  }
}

/* REMOVE LIMITED AREA */
const REMOVE_LIMITED_AREA_START = 'REMOVE_LIMITED_AREA_START'
const removeLimitedAreaStart = () => ({
  type: REMOVE_LIMITED_AREA_START,
})

const REMOVE_LIMITED_AREA_FAIL = 'REMOVE_LIMITED_AREA_FAIL'
const removeLimitedAreaFail = () => ({
  type: REMOVE_LIMITED_AREA_FAIL,
})

const REMOVE_LIMITED_AREA_SUCCESS = 'REMOVE_LIMITED_AREA_SUCCESS'
const removeLimitedAreaSuccess = (limitedArea) => ({
  type: REMOVE_LIMITED_AREA_SUCCESS,
  payload: limitedArea,
})

export const remove = (limitedAreaId) => async (dispatch) => {
  dispatch(removeLimitedAreaStart())
  try {
    await axios.delete(`/admin/limitedareas/${limitedAreaId}`)
    dispatch(removeLimitedAreaSuccess(limitedAreaId))
  } catch (e) {
    dispatch(removeLimitedAreaFail())
  }
}

/* FETCH LIMITED AREAS */
const FETCH_LIMITED_AREAS_START = 'FETCH_LIMITED_AREAS_START'
const fetchLimitedAreasStart = () => ({
  type: FETCH_LIMITED_AREAS_START,
})

const FETCH_LIMITED_AREAS_FAIL = 'FETCH_LIMITED_AREAS_FAIL'
const fetchLimitedAreasFail = () => ({
  type: FETCH_LIMITED_AREAS_FAIL,
})

const FETCH_LIMITED_AREAS_SUCCESS = 'FETCH_LIMITED_AREAS_SUCCESS'
const fetchLimitedAreasSuccess = (limitedAreasList) => ({
  type: FETCH_LIMITED_AREAS_SUCCESS,
  payload: limitedAreasList,
})

export const fetchAll = () => async (dispatch) => {
  dispatch(fetchLimitedAreasStart())
  try {
    const { data } = await axios.get('/admin/limitedareas')
    dispatch(fetchLimitedAreasSuccess(data))
  } catch (e) {
    dispatch(fetchLimitedAreasFail())
    dispatch(setSnackbar('Ett oväntad fel uppstod', 'error'))
  }
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_CREATE_OR_EDIT:
      return {
        ...state,
        limitedArea: action.payload,
        isCreatingOrEditing: !state.isCreatingOrEditing,
      }
    case HANDLE_NAME_CHANGE:
      return {
        ...state,
        limitedArea: { ...state.limitedArea, name: action.payload },
      }
    case HANDLE_DISTANCE_CHANGE:
      return {
        ...state,
        limitedArea: { ...state.limitedArea, distance: action.payload },
      }
    case HANDLE_SPEED_LIMIT_CHANGE:
      return {
        ...state,
        limitedArea: { ...state.limitedArea, speedLimit: action.payload },
      }
    case FETCH_LIMITED_AREAS_START:
      return { ...state, isLoadingFetch: true }
    case FETCH_LIMITED_AREAS_FAIL:
      return { ...state, isLoadingFetch: false }
    case FETCH_LIMITED_AREAS_SUCCESS:
      return { ...state, isLoadingFetch: false }
    case CREATE_LIMITED_AREA_START:
      return { ...state, isLoadingCreateOrUpdate: true }
    case CREATE_LIMITED_AREA_FAIL:
      return { ...state, isLoadingCreateOrUpdate: false }
    case CREATE_LIMITED_AREA_SUCCESS:
      return {
        ...state,
        isLoadingCreateOrUpdate: false,
        list: [...state.list, action.payload],
        limitedArea: null,
        isCreatingOrEditing: false,
      }
    case UPDATE_LIMITED_AREA_START:
      return { ...state, isLoadingCreateOrUpdate: true }
    case UPDATE_LIMITED_AREA_FAIL:
      return { ...state, isLoadingCreateOrUpdate: false }
    case UPDATE_LIMITED_AREA_SUCCESS: {
      const list = replace(
        { id: action.payload.id },
        action.payload,
        state.list
      )
      return {
        ...state,
        isLoadingCreateOrUpdate: false,
        list,
        limitedArea: null,
        isCreatingOrEditing: false,
      }
    }
    case REMOVE_LIMITED_AREA_START:
      return { ...state, isLoadingRemove: true }
    case REMOVE_LIMITED_AREA_FAIL:
      return { ...state, isLoadingRemove: false }
    case REMOVE_LIMITED_AREA_SUCCESS: {
      const list = replace({ id: action.payload }, null, state.list)
      return {
        ...state,
        isLoadingRemove: false,
        list,
      }
    }
    default:
      return state
  }
}

export default reducer
