import React from 'react'
import Home from '@material-ui/icons/DashboardOutlined'
import Boats from '@material-ui/icons/DirectionsBoatOutlined'
import Routes from '@material-ui/icons/ShareOutlined'
import Users from '@material-ui/icons/GroupOutlined'
import Order from '@material-ui/icons/LibraryBooksOutlined'
import Docks from '@material-ui/icons/PublicOutlined'
import TrackingIcon from '@material-ui/icons/GpsFixed'
import LimitedArea from '@material-ui/icons/NewReleasesOutlined'
import Organisations from '@material-ui/icons/LanguageOutlined'
import Settings from '@material-ui/icons/SettingsOutlined'
import Reports from '@material-ui/icons/Description'
import Logout from '@material-ui/icons/ExitToApp'
import { OrganisationTypesObject, Roles } from '../../helpers/permissions'

const items = [
  {
    name: 'Startsida',
    path: '/home',
    description: 'Denna sida.',
    icon: <Home style={{ fontSize: 30 }} />,
    access: [
      OrganisationTypesObject.INTERNAL,
      OrganisationTypesObject.PARTNER,
      OrganisationTypesObject.SUBCONTRACTOR,
    ],
    roles: Object.values(Roles),
  },
  {
    name: 'Orderhantering',
    path: '/orders',
    description:
      'Överblicka planerade, pågående och avslutade leveranser. Du kan även klicka in dig på detaljer för en leverans samt ändra något vid behov.',
    icon: <Order style={{ fontSize: 30 }} />,
    access: [OrganisationTypesObject.INTERNAL, OrganisationTypesObject.PARTNER],
    roles: [Roles.ADMIN, Roles.PARTNER_ADMIN, Roles.PARTNER_USER],
  },
  {
    name: 'Planering',
    path: '/planning',
    icon: <Routes style={{ fontSize: 30 }} />,
    access: [OrganisationTypesObject.INTERNAL],
  },
  {
    name: 'Översikt',
    path: '/tracking',
    icon: <TrackingIcon style={{ fontSize: 30 }} />,
    access: [OrganisationTypesObject.INTERNAL],
    roles: [Roles.ADMIN, Roles.SUBCONTRACTOR_ADMIN],
  },
  {
    name: 'Hamnar',
    path: '/docks',
    icon: <Docks style={{ fontSize: 30 }} />,
    access: [OrganisationTypesObject.INTERNAL],
  },
  {
    name: 'Båtar',
    path: '/boats',
    icon: <Boats style={{ fontSize: 30 }} />,
    access: [
      OrganisationTypesObject.INTERNAL,
      OrganisationTypesObject.SUBCONTRACTOR,
    ],
  },
  {
    name: 'Begränsningsområden',
    path: '/limited-areas',
    icon: <LimitedArea style={{ fontSize: 30 }} />,
    access: [OrganisationTypesObject.INTERNAL],
  },
  {
    name: 'Rapporter',
    path: '/reports',
    description:
      'Hämta ut rapporter gällande dina leveranser. Denna funktion är just nu begränsad.',
    icon: <Reports style={{ fontSize: 30 }} />,
    access: [OrganisationTypesObject.INTERNAL, OrganisationTypesObject.PARTNER],
  },
  {
    name: 'Användare',
    path: '/users',
    description:
      'Här kan du administrera användare. Endast för administratörer.',
    icon: <Users style={{ fontSize: 30 }} />,
    access: [
      OrganisationTypesObject.INTERNAL,
      OrganisationTypesObject.PARTNER,
      OrganisationTypesObject.SUBCONTRACTOR,
    ],
    roles: [Roles.ADMIN, Roles.PARTNER_ADMIN, Roles.SUBCONTRACTOR_ADMIN],
  },
  {
    name: 'Företag',
    path: '/organisations',
    icon: <Organisations style={{ fontSize: 30 }} />,
    access: [OrganisationTypesObject.INTERNAL],
  },
  {
    name: 'Inställningar',
    path: '/settings',
    icon: <Settings style={{ fontSize: 30 }} />,
    access: [],
  },
  {
    name: 'Logga ut',
    path: '/logout',
    icon: <Logout style={{ fontSize: 30 }} />,
    access: [],
  },
]

// Admin -> Ruttning, hamnar, båtar, begränsningar, logga ut
// Partner -> Orderhantering, Logga ut

export default items
