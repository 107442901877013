import axios from 'axios'
import { push } from 'connected-react-router'
import { setSnackbar } from './snackbar'

const initialState = {
  list: [],
  isLoadingFetch: false,
  isLoadingCreate: false,
  isLoadingFetchSingle: false,
  isLoadingUpdate: false,
  isLoadingTypeChange: false,
  objectToCreate: {},
  objectToView: {},
}

const FETCH_ORGANISATIONS_START = 'FETCH_ORGANISATIONS_START'
const fetchOrganisationsStart = () => ({
  type: FETCH_ORGANISATIONS_START,
})

const FETCH_ORGANISATIONS_FAIL = 'FETCH_ORGANISATIONS_FAIL'
const fetchOrganisationsFail = () => ({
  type: FETCH_ORGANISATIONS_FAIL,
})

const FETCH_ORGANISATIONS_SUCCESS = 'FETCH_ORGANISATIONS_SUCCESS'
const fetchOrganisationsSuccess = (list) => ({
  type: FETCH_ORGANISATIONS_SUCCESS,
  payload: list,
})

export const fetchAll = () => async (dispatch) => {
  dispatch(fetchOrganisationsStart())
  try {
    const { data } = await axios.get('/v2/organisations')
    dispatch(fetchOrganisationsSuccess(data))
  } catch (e) {
    dispatch(fetchOrganisationsFail())
    dispatch(setSnackbar(e, 'error'))
  }
}

const ORGANISATIONS_OBJECT_TO_CREATE_CHANGED =
  'ORGANISATIONS_OBJECT_TO_CREATE_CHANGED'
export const onObjectToCreateChange = (object) => ({
  type: ORGANISATIONS_OBJECT_TO_CREATE_CHANGED,
  payload: object,
})

const CREATE_ORGANISATION_START = 'CREATE_ORGANISATION_START'
const createOrganisationStart = () => ({
  type: CREATE_ORGANISATION_START,
})

const CREATE_ORGANISATION_FAIL = 'CREATE_ORGANISATION_FAIL'
const createOrganisationFail = () => ({
  type: CREATE_ORGANISATION_FAIL,
})

const CREATE_ORGANISATION_SUCCESS = 'CREATE_ORGANISATION_SUCCESS'
const createOrganisationSuccess = (data) => ({
  type: CREATE_ORGANISATION_SUCCESS,
  payload: data,
})

const sleep = (ms) =>
  new Promise((resolve) => {
    setTimeout(resolve, ms)
  })
export const createOrganisation = () => async (dispatch, getState) => {
  const { objectToCreate } = getState().organisations
  dispatch(createOrganisationStart())
  try {
    const { data } = await axios.post('/v2/organisations', objectToCreate)
    dispatch(createOrganisationSuccess(data))
    // Redirect to that organisations' detail view
    dispatch(push(`/organisations/${data.id}`))
  } catch (e) {
    dispatch(createOrganisationFail())
    dispatch(setSnackbar(e, 'error'))
  }
}

const FETCH_SINGLE_ORGANISATION_START = 'FETCH_SINGLE_ORGANISATION_START'
const fetchSingleOrganisationStart = () => ({
  type: FETCH_SINGLE_ORGANISATION_START,
})

const FETCH_SINGLE_ORGANISATION_FAIL = 'FETCH_SINGLE_ORGANISATION_FAIL'
const fetchSingleOrganisationFail = () => ({
  type: FETCH_SINGLE_ORGANISATION_FAIL,
})

const FETCH_SINGLE_ORGANISATION_SUCCESS = 'FETCH_SINGLE_ORGANISATION_SUCCESS'
const fetchSingleOrganisationSuccess = (data) => ({
  type: FETCH_SINGLE_ORGANISATION_SUCCESS,
  payload: data,
})

export const fetchSingleOrganisation = (id) => async (dispatch) => {
  dispatch(fetchSingleOrganisationStart())
  try {
    const { data } = await axios.get(`/v2/organisations/${id}?include=Users`)
    dispatch(fetchSingleOrganisationSuccess(data))
  } catch (e) {
    dispatch(fetchSingleOrganisationFail())
    dispatch(setSnackbar(e, 'error'))
  }
}

const ORGANISATIONS_OBJECT_TO_VIEW_CHANGED =
  'ORGANISATIONS_OBJECT_TO_VIEW_CHANGED'
export const onObjectToViewChange = (object) => ({
  type: ORGANISATIONS_OBJECT_TO_VIEW_CHANGED,
  payload: object,
})

const UPDATE_ORGANISATION_START = 'UPDATE_ORGANISATION_START'
const updateOrganisationStart = () => ({
  type: UPDATE_ORGANISATION_START,
})

const UPDATE_ORGANISATION_FAIL = 'UPDATE_ORGANISATION_FAIL'
const updateOrganisationFail = () => ({
  type: UPDATE_ORGANISATION_FAIL,
})

const UPDATE_ORGANISATION_SUCCESS = 'UPDATE_ORGANISATION_SUCCESS'
const updateOrganisationSuccess = () => ({
  type: UPDATE_ORGANISATION_SUCCESS,
})

export const updateOrganisation = () => async (dispatch, getState) => {
  const { objectToView } = getState().organisations
  dispatch(updateOrganisationStart())
  try {
    const { data } = await axios.patch(
      `/v2/organisations/${objectToView.id}`,
      objectToView
    )
    dispatch(updateOrganisationSuccess(data))
    // Redirect to that organisations' detail view
  } catch (e) {
    dispatch(updateOrganisationFail())
    dispatch(setSnackbar(e, 'error'))
  }
}

const ADD_ORGANISATION_TYPE_START = 'ADD_ORGANISATION_TYPE_START'
const addOrganisationTypeStart = () => ({
  type: ADD_ORGANISATION_TYPE_START,
})

const ADD_ORGANISATION_TYPE_FAIL = 'ADD_ORGANISATION_TYPE_FAIL'
const addOrganisationTypeFail = () => ({
  type: ADD_ORGANISATION_TYPE_FAIL,
})

const ADD_ORGANISATION_TYPE_SUCCESS = 'ADD_ORGANISATION_TYPE_SUCCESS'
const addOrganisationTypeSuccess = (data) => ({
  type: ADD_ORGANISATION_TYPE_SUCCESS,
  payload: data,
})

export const addOrganisationType = (type) => async (dispatch, getState) => {
  const { objectToView } = getState().organisations
  dispatch(addOrganisationTypeStart())
  try {
    const { data } = await axios.post(
      `/v2/organisations/${objectToView.id}/type?type=${type}`
    )
    dispatch(addOrganisationTypeSuccess(data))
  } catch (e) {
    dispatch(addOrganisationTypeFail())
    dispatch(setSnackbar(e, 'error'))
  }
}

const REMOVE_ORGANISATION_TYPE_START = 'REMOVE_ORGANISATION_TYPE_START'
const removeOrganisationTypeStart = () => ({
  type: REMOVE_ORGANISATION_TYPE_START,
})

const REMOVE_ORGANISATION_TYPE_FAIL = 'REMOVE_ORGANISATION_TYPE_FAIL'
const removeOrganisationTypeFail = () => ({
  type: REMOVE_ORGANISATION_TYPE_FAIL,
})

const REMOVE_ORGANISATION_TYPE_SUCCESS = 'REMOVE_ORGANISATION_TYPE_SUCCESS'
const removeOrganisationTypeSuccess = (type) => ({
  type: REMOVE_ORGANISATION_TYPE_SUCCESS,
  payload: type,
})

export const removeOrganisationType = (type) => async (dispatch, getState) => {
  const { objectToView } = getState().organisations
  dispatch(removeOrganisationTypeStart())
  try {
    await axios.delete(`/v2/organisations/${objectToView.id}/type?type=${type}`)
    dispatch(removeOrganisationTypeSuccess(type))
  } catch (e) {
    dispatch(removeOrganisationTypeFail())
    dispatch(setSnackbar(e, 'error'))
  }
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ORGANISATIONS_START:
      return { ...state, isLoadingFetch: true }
    case FETCH_ORGANISATIONS_FAIL:
      return { ...state, isLoadingFetch: false }
    case FETCH_ORGANISATIONS_SUCCESS:
      return { ...state, isLoadingFetch: false, list: action.payload }
    case ORGANISATIONS_OBJECT_TO_CREATE_CHANGED:
      return { ...state, objectToCreate: action.payload }
    case CREATE_ORGANISATION_START:
      return { ...state, isLoadingCreate: true }
    case CREATE_ORGANISATION_FAIL:
      return { ...state, isLoadingCreate: false }
    case CREATE_ORGANISATION_SUCCESS:
      return { ...state, isLoadingCreate: false, objectToCreate: {} }
    case ORGANISATIONS_OBJECT_TO_VIEW_CHANGED:
      return { ...state, objectToView: action.payload }
    case UPDATE_ORGANISATION_START:
      return { ...state, isLoadingUpdate: true }
    case UPDATE_ORGANISATION_FAIL:
      return { ...state, isLoadingUpdate: false }
    case UPDATE_ORGANISATION_SUCCESS:
      return { ...state, isLoadingUpdate: false }
    case FETCH_SINGLE_ORGANISATION_START:
      return { ...state, isLoadingFetchSingle: true, objectToView: {} }
    case FETCH_SINGLE_ORGANISATION_FAIL:
      return { ...state, isLoadingFetchSingle: false }
    case FETCH_SINGLE_ORGANISATION_SUCCESS:
      return {
        ...state,
        isLoadingFetchSingle: false,
        objectToView: action.payload,
      }
    case ADD_ORGANISATION_TYPE_START:
      return { ...state, isLoadingTypeChange: true }
    case ADD_ORGANISATION_TYPE_FAIL:
      return { ...state, isLoadingTypeChange: false }
    case ADD_ORGANISATION_TYPE_SUCCESS: {
      const objectToView = { ...state.objectToView }
      objectToView.organisationTypes = [...state.objectToView.organisationTypes]
      objectToView.organisationTypes.push(action.payload)
      return { ...state, isLoadingTypeChange: false, objectToView }
    }
    case REMOVE_ORGANISATION_TYPE_START:
      return { ...state, isLoadingTypeChange: true }
    case REMOVE_ORGANISATION_TYPE_FAIL:
      return { ...state, isLoadingTypeChange: false }
    case REMOVE_ORGANISATION_TYPE_SUCCESS: {
      const objectToView = { ...state.objectToView }
      objectToView.organisationTypes = objectToView.organisationTypes.filter(
        (type) => type.type !== action.payload
      )
      return { ...state, isLoadingTypeChange: false, objectToView }
    }
    default:
      return state
  }
}

export default reducer
