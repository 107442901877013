import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import injectSheet from 'react-jss'
import Breadcrumbs from '@material-ui/core/Breadcrumbs'
import Icon from '@material-ui/core/Icon'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import axios from 'axios'
import { Button, Radio, RadioGroup } from '@material-ui/core'
import { connect } from 'react-redux'
import Modal from './Modal'
import Notifications from './Notifications'
import { history } from '../redux'
import { loggedIn } from '../redux/reducers/login'
import Column from './Column'
import Row from './Row'
import withUser from '../utils/withUser'

const styles = {
  root: {},
  paper: {},
  breadcrumbsContainer: {
    padding: '32px 16px 16px 16px',
  },
}

type Organisation = {
  id: number
  name: string
  organisationNumber: string
}
const OrganisationPicker = (props: any) => {
  const [organisation, setOrganisation] = useState<Organisation>(
    props.user.organisation
  )
  const [organisations, setOrganisations] = useState<Organisation[]>([])

  useEffect(() => {
    if (props.open) {
      ;(async () => {
        try {
          const { data } = await axios.get('/v2/organisations?self=true')
          setOrganisations(data)
        } catch (e: any) {
          const msg = e.response?.data ?? e.message
          Notifications.error(msg, 'OrganisationPicker.GetOrganisations')
        }
      })()
    }
  }, [props.open])

  const handleSelect = async () => {
    try {
      await axios.patch(`/v2/users/${props.user.id}`, {
        defaultOrganisationId: organisation.id,
      })
      const { data } = await axios.get('/v2/auth/refresh')
      props.dispatch(loggedIn(data))
      if (props.onClose) {
        props.onClose()
      }
      history.push('/')
    } catch (e: any) {
      const msg = e.response?.data ?? e.message
      Notifications.error(msg, 'OrganisationPicker.SelectOrganisation')
    }
  }

  return (
    <Modal open={props.open} title="Välj vilken organisation du vill företräda">
      <Column>
        {organisations.map((org) => (
          <Row
            key={org.id}
            style={{ marginTop: 16, flex: 0 }}
            onClick={() => setOrganisation(org)}
          >
            <Radio
              checked={organisation.id === org.id}
              style={{ width: 50, height: 50 }}
            />
            <Column
              style={{
                marginLeft: 24,
                paddingBottom: 16,
                borderBottom: '1px solid #e7e7e7',
                width: '100%',
              }}
            >
              <Typography style={{ fontWeight: 500 }}>{org.name}</Typography>
              <Typography style={{ color: '#777' }}>
                {org.organisationNumber}
              </Typography>
            </Column>
          </Row>
        ))}
        <Row justify="flex-end" style={{ marginTop: 32 }}>
          <Button
            variant="outlined"
            color="default"
            style={{ marginRight: 24, maxWidth: 150 }}
            onClick={props.onClose}
          >
            Avbryt
          </Button>
          <Button
            variant="contained"
            color="primary"
            style={{ maxWidth: 150 }}
            onClick={handleSelect}
          >
            Välj
          </Button>
        </Row>
      </Column>
    </Modal>
  )
}

export default connect()(withUser(injectSheet(styles)(OrganisationPicker)))
