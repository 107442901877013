import axios from 'axios'
import { push } from 'connected-react-router'
import { setSnackbar } from './snackbar'

const initialState = {
  email: null,
  password: null,
  user: null,
  token: null,
  expires: null,
  isLoadingSubmit: false,
  isLoadingRecover: false,
  isRecoveryModalOpen: false,
}

const initialExpires = localStorage.getItem('expires')
if (initialExpires) {
  const currentTime = new Date().getTime() / 1000
  if (initialExpires > currentTime) {
    const userJson = localStorage.getItem('user')
    const token = localStorage.getItem('token')
    if (userJson && token) {
      try {
        initialState.user = JSON.parse(userJson)
        initialState.expires = initialExpires
        initialState.token = token
      } catch (e) {
        console.log('Failed to parse user (localStorage["user"])')
      }
    }
  }
}

const sleep = (ms) =>
  new Promise((resolve) => {
    setTimeout(resolve, ms)
  })

const SET_EMAIL = 'SET_EMAIL'
export const setEmail = (input) => ({
  type: SET_EMAIL,
  payload: input,
})

const SET_PASSWORD = 'SET_PASSWORD'
export const setPassword = (input) => ({
  type: SET_PASSWORD,
  payload: input,
})

const LOGIN_START = 'LOGIN_START'
const loginStart = () => ({
  type: LOGIN_START,
})

const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const loginSuccess = ({ user, token, expires }) => ({
  type: LOGIN_SUCCESS,
  payload: { user, token, expires },
})

const LOGIN_FAIL = 'LOGIN_FAIL'
const loginFail = () => ({
  type: LOGIN_FAIL,
})

export const loggedIn =
  ({ user, token, expires }) =>
  (dispatch, getState) => {
    // const { email, password } = getState().login
    // dispatch(loginStart())
    // try {
    // const { data } = await axios.post('/v2/auth', { email, password })
    // const { user, token, expires } = data
    dispatch(loginSuccess({ user, token, expires }))
    //   if (user.roles.every(role => role === 'DRIVER')) {
    //     dispatch(push('/driver'))
    //   } else {
    //     dispatch(push('/home'))
    //   }
    // } catch (e) {
    //   dispatch(loginFail())
    //   dispatch(setSnackbar(e, 'error'))
    // }
  }

export const login = () => async (dispatch, getState) => {
  const { email, password } = getState().login
  dispatch(loginStart())
  try {
    const { data } = await axios.post('/v2/auth', { email, password })
    const { user, token, expires } = data
    dispatch(loginSuccess({ user, token, expires }))
    if (user.roles.every((role) => role === 'DRIVER')) {
      dispatch(push('/driver'))
    } else {
      dispatch(push('/home'))
    }
  } catch (e) {
    dispatch(loginFail())
    dispatch(setSnackbar(e, 'error'))
  }
}

const TOGGLE_RECOVERY_MODAL = 'TOGGLE_RECOVERY_MODAL'
export const toggleRecoveryModal = () => ({
  type: TOGGLE_RECOVERY_MODAL,
})

const RECOVER_ACCOUNT_START = 'RECOVER_ACCOUNT_START'
const recoverAccountStart = () => ({
  type: RECOVER_ACCOUNT_START,
})

const RECOVER_ACCOUNT_FAIL = 'RECOVER_ACCOUNT_FAIL'
const recoverAccountFail = () => ({
  type: RECOVER_ACCOUNT_FAIL,
})

const RECOVER_ACCOUNT_SUCCESS = 'RECOVER_ACCOUNT_SUCCESS'
const recoverAccountSuccess = () => ({
  type: RECOVER_ACCOUNT_SUCCESS,
})

export const recoverAccount = (email) => async (dispatch) => {
  dispatch(recoverAccountStart())
  try {
    await sleep(1500)
    await axios.post('/v2/users/recover', { email })
    dispatch(recoverAccountSuccess())
    dispatch(
      setSnackbar(
        `En återställningslänk har skickats till ${email}.`,
        'success'
      )
    )
  } catch (e) {
    dispatch(recoverAccountFail())
    dispatch(setSnackbar('Ett oväntad fel uppstod', 'error'))
  }
}

const LOGOUT = 'LOGOUT'
export const logout = () => (dispatch) => {
  localStorage.clear()
  dispatch({ type: LOGOUT })
  dispatch(push('/login'))
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_EMAIL:
      return { ...state, email: action.payload }
    case SET_PASSWORD:
      return { ...state, password: action.payload }
    case LOGIN_START:
      return { ...state, isLoadingSubmit: true }
    case LOGIN_FAIL:
      return { ...state, isLoadingSubmit: false }
    case LOGIN_SUCCESS: {
      const { user, token, expires } = action.payload
      localStorage.setItem('user', JSON.stringify(user))
      localStorage.setItem('token', token)
      localStorage.setItem('expires', expires)
      return {
        ...state,
        isLoadingSubmit: false,
        email: null,
        password: null,
        user,
        token,
        expires,
      }
    }
    case TOGGLE_RECOVERY_MODAL:
      return { ...state, isRecoveryModalOpen: !state.isRecoveryModalOpen }
    case RECOVER_ACCOUNT_START:
      return { ...state, isLoadingRecover: true }
    case RECOVER_ACCOUNT_FAIL:
      return { ...state, isLoadingRecover: false }
    case RECOVER_ACCOUNT_SUCCESS:
      return {
        ...state,
        isLoadingRecover: false,
        isRecoveryModalOpen: false,
      }
    case LOGOUT:
      return { initialState }
    default:
      return state
  }
}

export default reducer
