/*
 *
 * DO NOT USE ANY SECRETS HERE
 *
 */
const { origin } = window.location
let iconSrc = `${origin}/pwa-swepelago-512.png`
let favicon = `${origin}/favicon.png`

const manifest = {
  short_name: 'SwePelago',
  name: 'SwePelago',
  description: 'Skärgårdsportalen',
  icons: [
    {
      src: iconSrc,
      sizes: '512x512',
      type: 'image/png',
    },
  ],
  start_url: origin,
  display: 'standalone',
  theme_color: '#33BEFA',
  orientation: 'landscape',
  background_color: '#33BEFA',
}

const environments = {
  localhost: 'dev',
  'portal.swepelago.se': 'SWPL_PROD',
  'portal.kustbud.se': 'KB_PROD',
}
const { hostname } = window.location

const currentEnv = environments[hostname]

if (!window.env || typeof window.env !== 'object') {
  window.env = {
    name: currentEnv,
  }
}

if (currentEnv === 'dev') {
  window.env.API_BASE_URL = 'http://localhost:3000'
  favicon = `${origin}/kustbud-favicon-64.png`
  iconSrc = `${origin}/pwa-swepelago-512.png`
  manifest.short_name = 'SwePelago_DEV'
  manifest.start_url = origin
  manifest.name = 'SwePelago_DEV'
  manifest.icons = [
    {
      src: iconSrc,
      sizes: '512x512',
      type: 'image/png',
    },
  ]
} else if (currentEnv === 'SWPL_PROD') {
  window.env.API_BASE_URL = 'https://api.swepelago.se'
  favicon = `${origin}/favicon.png`
  iconSrc = `${origin}/pwa-swepelago-512.png`
  manifest.short_name = 'SwePelago'
  manifest.start_url = origin
  manifest.name = 'SwePelago'
  manifest.icons = [
    {
      src: iconSrc,
      sizes: '512x512',
      type: 'image/png',
    },
  ]
} else if (currentEnv === 'KB_PROD') {
  window.env.API_BASE_URL = 'https://api.kustbud.se'
  favicon = `${origin}/kustbud-favicon-64.png`
  iconSrc = `${origin}/pwa-kustbud-512.png`
  manifest.short_name = 'Kustbud'
  manifest.start_url = origin
  manifest.name = 'Kustbud'
  manifest.icons = [
    {
      src: iconSrc,
      sizes: '512x512',
      type: 'image/png',
    },
  ]
} else if (/^\d{1,3}.\d{1,3}.\d{1,3}.\d{1,3}$/.test(hostname)) {
  /* Attempt to create API URL from hostname on local networks, or IP to be exact */
  window.env.API_BASE_URL = `http://${hostname}:3000`
} else {
  console.error('Could not determine environment. Check -> src/env.js')
}
const stringManifest = JSON.stringify(manifest)
const blob = new Blob([stringManifest], { type: 'application/json' })
const manifestURL = URL.createObjectURL(blob)
document.querySelector('#header-favicon').setAttribute('href', favicon)
document.querySelector('#header-manifest').setAttribute('href', manifestURL)
document.querySelector('#header-apple-touch-icon').setAttribute('href', iconSrc)
